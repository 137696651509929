import React, { useEffect, useRef } from 'react';
import './Sidebar.css';

const Sidebar = ({ isOpen, toggleSidebar, isLoggedIn, onLogout }) => {
  const isAdmin = localStorage.getItem('is_admin') === '1'; // Check admin status
  const sidebarRef = useRef(null);
  const menuButtonRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!isOpen) return; // Do nothing if sidebar is already closed

      // Close sidebar only if clicking outside of both sidebar and menu button
      if (
        sidebarRef.current && !sidebarRef.current.contains(event.target) &&
        menuButtonRef.current && !menuButtonRef.current.contains(event.target)
      ) {
        toggleSidebar(false); // Explicitly set to false when clicking outside
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, toggleSidebar]);

  return (
    <>
      <button ref={menuButtonRef} className="menu-btn" onClick={() => toggleSidebar(!isOpen)}>
        ☰
      </button>
      <div ref={sidebarRef} className={`sidebar ${isOpen ? 'open' : 'collapsed'}`}>
        <nav>
          <ul>
            <li><a href="/"><i className="icon-home"></i><span>Home</span></a></li>
            <li><a href="/ashrae15"><i className="icon-dashboard"></i><span>Commercial</span></a></li>
            <li><a href="/ashrae152"><i className="icon-dashboard"></i><span>Residential</span></a></li>
            {isLoggedIn && (
              <>
                <hr className="sidebar-divider" />
                <li><a href="/MyAccount"><i className="icon-user"></i><span>My Account</span></a></li>
                {isAdmin && (
                  <li><a href="/Admin"><i className="icon-admin"></i><span>Admin</span></a></li>
                )}
                <li><button onClick={onLogout} className="logout-btn"><i className="icon-logout"></i><span>Logout</span></button></li>
              </>
            )}
          </ul>
        </nav>
      </div>
    </>
  );
};

export default Sidebar;
