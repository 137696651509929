import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './Header.css';
import logo from './assets/images/RCLcheck.com_alt1.svg';

const Header = ({ isLoggedIn, onLogout }) => {
  const location = useLocation();

  const isCompressedPage = location.pathname === '/ashrae15' || '/ashrae152';

  useEffect(() => {
    const handleScroll = () => {
      const header = document.querySelector('.site-header');
      const logoImg = document.querySelector('.logo img');

      if (!isCompressedPage) {
        if (window.scrollY > 100) {
          header.style.height = '60px';
          logoImg.style.width = '200px';
        } else {
          header.style.height = '100px';
          logoImg.style.width = '400px';
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isCompressedPage]);

  useEffect(() => {
    if (isCompressedPage) {
      document.body.style.paddingTop = '0px'; // Adjust padding for compressed header
    } else {
      document.body.style.paddingTop = '100px'; // Default padding for regular header
    }
  }, [isCompressedPage]);

  return (
    <header className={`site-header ${isCompressedPage ? 'compressed' : ''}`}>
      <div className="header-content">
        <div className="logo">
          <img src={logo} alt="RCLcheck.com Logo" />
        </div>

        {/* Search Box */}
{/*         <div className="search-box">
            <input type="text" placeholder="This will search the wiki!!!    And should auto complete!!!" />
            <button type="button">
              <i className="fas fa-search"></i> 
            </button>
        </div> */}

        <div className="header-actions">      

          {/* Language Switcher */}
{/*           <div className="language-switcher">
            <button type="button" class="language-switcher">
              <a><i class="fas fa-globe"></i></a>
            </button>
          </div> */}

          {/* Log In / Log Out Button */}
          {isLoggedIn ? (
            <button onClick={onLogout} className="login-button">
              <i className="fa-regular fa-user"></i> Log Out
            </button>
          ) : (
            <a href="/login" className="login-button">
              <i className="fa-regular fa-user"></i> Log In
            </a>
          )}

          {/* Contact Us Button */}
          <a href="#contact" className="contact-button"><i class="fa-solid fa-envelope"></i> Contact</a>

          {/* Mobile Icons for smaller screens */}
          <div className="mobile-icons">
            <a href="#seach"><i className="fas fa-search"></i></a> {/* User icon for login */}
            <button type="button" class="language-switcher">
              <a><i class="fas fa-globe"></i></a>
            </button>
            <a href="#login"><i className="fas fa-user icon"></i></a> {/* User icon for login */}
            <a href="#contact"><i className="fas fa-envelope icon"></i></a> {/* Mail icon for contact */}
          </div>

        </div>
      </div>
    </header>
  );
};

export default Header;
