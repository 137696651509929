import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Ashrae152.css';
import './Ashrae15.css';
import './styles.css';
import { FaInfoCircle, FaCheck, FaFan, FaCircleNotch, FaWind } from 'react-icons/fa'; // Import info icon

const Ashrae152 = () => {
  const navigate = useNavigate();

//Won't need this old code, but keeping it until the 15.2 page is transitioned 
   const [units, setUnits] = useState([{
    tag: '',
    unitType: '',
    roomArea: '',
    installationHeight: '',
    releaseOffset: '',
    ceilingHeight: '',
    liquidPipeDiameter: '',
    liquidPipeLength: '',
    suctionPipeDiameter: '',
    suctionPipeLength: '',
    indoorCoilVolume: '',
    includesRDS: false,
    includesCirculation: false
  }]); // Default to 1 unit on page load  

  const [selectedSpaceIndex, setSelectedSpaceIndex] = useState(0); // Selected row index
  const [spaces, setSpaces] = useState([
    {
      tag: "Default Space", // Provide a default tag
      unitConfiguration: {
        tag: "Default Unit",
        isDuctless: false,
        installationHeight: 0,
        releaseOffset: 0,
        h_c: "",
        indoorCoilVolume: 0,
        includesRDS: false,
        includesCirculation: false,
        isCompliant: false,
        ventilationCFM: 0,
        safetyFactor: 4,
      },
      spaceConfiguration: {
        spaceName: "",
        area: 0,
        spaceHeight: 0,
        V_eff: 0,
        mMax: 0,
        isConnected: false,
        includesMechVentilation: false,
        includesNaturalVentilation: false,
        includesDuctwork:false,
        ductworkVolume: "",
        plenumVolume: "",
        F_occ: 1,
        M: 0,
        MV: 0,
      },
    },  
  ]); // Array of space objects to store each space's configuration
  const [switches, setSwitchStates] = useState({
    switch1: false, //Release Mitigation for system
    switch2: false, //RDS
    switch3: false, //Circulation
    switch4: false, //Atrium
    switch5: false, //Connected spaces
    switch6: false, //Mech ventilation
    switch7: false, //Natural ventilation
    switch8: false, //includes Ductwork
    switch9: false, //is Ductless
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openModals, setOpenModals] = useState({});
  const [onModalConfirm, setOnModalConfirm] = useState(() => () => {});
  const [error, setError] = useState(''); // To display error messages
  const [factoryCharge, setFactoryCharge] = useState(''); // Mandatory field
  const [fieldCharge, setFieldCharge] = useState('');     // Optional field
  const [refrigerantType, setRefrigerantType] = useState(''); // Track refrigerant type
  const [sysCharge, setSysCharge] = useState(0);
  const [mMax, setmMax] = useState(0);
  const [installHeight, setInstallHeight] = useState(''); // Track install height
  const [releaseOffset, setReleaseOffset] = useState(''); // Track release offset
  const [spaceHeight, setSpaceHeight] = useState(0);
  const [spaceName, setSpaceName] = useState('');
  const [area, setArea] = useState('');
  const [calculationResult, setCalculationResult] = useState(null);
  const [ventilationCFM, setVentilationCFM] = useState(0);
  const [ductworkVolume, setDuctworkVolume] = useState(''); // Track ductwork volume
  const [plenumVolume, setPlenumVolume] = useState(''); // Track plenum volume
  const [isCalculateEnabled, setIsCalculateEnabled] = useState(false);
  const [missingInputs, setMissingInputs] = useState([]);
  const [safetyFactor, setSafetyFactor] = useState(0);
  const refrigerantData = {
    "R-410A": { C_LFL: "-", F_LFL: "-", LFL: "-" }, //LFL in lb/1000 ft^3
    "R-32":   { C_LFL: 1.0, F_LFL: 1.0, LFL: 19.1 },
    "R-452B": { C_LFL: 1.02, F_LFL: 1.02, LFL: 19.3 },
    "R-454A": { C_LFL: 0.90, F_LFL: 0.90, LFL: 17.5 },
    "R-454B": { C_LFL: 0.96, F_LFL: 0.96, LFL: 18.5 },
    "R-454C": { C_LFL: 0.94, F_LFL: 0.94, LFL: 18.2 },
    "R-457A": { C_LFL: 0.65, F_LFL: 0.65, LFL: 13.5 },
  };
  const [C_LFL, setC_LFL] = useState(null);
  const [F_LFL, setF_LFL] = useState(null);
  const [LFL, setLFL] = useState(null);  
  // Add a new state to track if the calculation has been made
  const [calculationMade, setCalculationMade] = useState(false);

  // Function to check if all required fields are filled for each unit
  const allInputsValid = () => {
    // Check if the refrigerant type is selected
    if (!refrigerantType) {
      return false; // Return false if refrigerant is not selected
    }
  
    // Ensure factoryCharge is filled
    if (!factoryCharge) {
      return false;
    }
  
    // Validate each space's configuration
    return spaces.every((space) => {
      const { spaceConfiguration, unitConfiguration } = space;
  
      // Common validation fields for spaceConfiguration
      const commonSpaceFieldsValid =
        spaceConfiguration.spaceName &&
        spaceConfiguration.area;
  
      // For ducted units, validate space height
      if (!unitConfiguration.isDuctless) {
        return (
          commonSpaceFieldsValid &&
          spaceConfiguration.spaceHeight
        );
      }
  
      // For ductless units, validate installation height
      if (unitConfiguration.isDuctless) {
        return (
          commonSpaceFieldsValid &&
          unitConfiguration.installationHeight
        );
      }
  
      // Default validation for other unit types (if applicable)
      return commonSpaceFieldsValid;
    });
  };  

  const getMissingInputs = () => {
    const missing = [];
  
    // Add generic errors for Refrigerant Type and Factory Charge
    if (!refrigerantType) {
      missing.push("- Refrigerant Type is required.");
    }
    if (!factoryCharge) {
      missing.push("- Factory Charge is required.");
    }
  
    // Add space-specific errors
    spaces.forEach((space, index) => {
      const { spaceConfiguration, unitConfiguration } = space;
  
      const spaceErrors = [];
  
      if (!spaceConfiguration.spaceName) {
        spaceErrors.push("- Space Name is required.");
      }
      if (!spaceConfiguration.area) {
        spaceErrors.push("- Area is required.");
      }
      if (!unitConfiguration.isDuctless && !spaceConfiguration.spaceHeight) {
        spaceErrors.push("- Space Height is required (for ducted units).");
      }
      if (unitConfiguration.isDuctless && !unitConfiguration.installationHeight) {
        spaceErrors.push("- Installation Height is required (for ductless units).");
      }
  
      // If any errors exist for this space, format them with a title
      if (spaceErrors.length > 0) {
        missing.push(`\nSpace ${index + 1}`);
        missing.push(...spaceErrors);
        missing.push("<hr />"); // Add horizontal break
      }
    });
  
    return missing;
  };  

  const saveCurrentSpaceConfig = () => {
    if (selectedSpaceIndex !== null && selectedSpaceIndex >= 0) {
      setSpaces((prevSpaces) => {
        const updatedSpaces = [...prevSpaces];
        const currentSpace = updatedSpaces[selectedSpaceIndex];
  
        // Preserve the existing V_eff
        const currentVeff = currentSpace.spaceConfiguration.V_eff;
        const currentEDVC = currentSpace.spaceConfiguration.EDVC;
        const currentM_def= currentSpace.unitConfiguration.M_def;
  
        updatedSpaces[selectedSpaceIndex] = {
          ...currentSpace,
          unitConfiguration: {
            ...currentSpace.unitConfiguration,
            M_def: currentM_def,
          },
          spaceConfiguration: {
            ...currentSpace.spaceConfiguration,
            installHeight,
            releaseOffset,
            spaceName,
            area,
            spaceHeight,
            ductworkVolume,
            plenumVolume,
            V_eff: currentVeff, // Preserve V_eff instead of resetting it
            EDVC: currentEDVC,
          },
        };
        return updatedSpaces;
      });
    }
  };

  const addSpace = () => {
    const newSpace = {
      unitConfiguration: {
        tag: '',
        isDuctless: false,
        installationHeight: '',
        releaseOffset: '',
        indoorCoilVolume: '',
        includesRDS: false,
        includesCirculation: false,
        isCompliant: false,
        ventilationCFM: 0,
        safetyFactor: 4,
      },
      spaceConfiguration: {
        spaceName: '',
        area: '',
        spaceHeight: '',
        factoryCharge: '',
        fieldCharge: '',
        refrigerantType: '',
        // Add any additional properties as needed
      }
    };
    setSpaces((prevSpaces) => {
      const updatedSpaces = [...prevSpaces, newSpace];
      // Select the newly added row (which will be at the last index)
      setSelectedSpaceIndex(updatedSpaces.length - 1);
      setTimeout(() => {
        if (nameInputRef.current) {
          nameInputRef.current.focus();
        }
      }, 0);
      return updatedSpaces;
    });

    // Reset toggles for RDS and Circulation
    setSwitchStates((prevSwitches) => ({
      ...prevSwitches,
      switch2: false, // includesRDS
      switch3: false, // includesCirculation
      switch9: false, // isDuctless
    }));

    //Clear input fields
    setInstallHeight('');
    setReleaseOffset('');
    setSpaceName('');
    setArea('');
    setSpaceHeight('');
    setDuctworkVolume('');
    setPlenumVolume('');
  };

  // Function to remove a space
  const removeSpace = (indexToRemove) => {
    if (spaces.length === 1) {
      alert("You must have at least one space.");
      return;
    }
    setSpaces((prevSpaces) => {
      const updatedSpaces = prevSpaces.filter((_, index) => index !== indexToRemove);
  
      // Determine the new selected index
      const newSelectedIndex = updatedSpaces.length > 0
        ? Math.min(indexToRemove, updatedSpaces.length - 1) // Select the next closest row
        : null;
  
      setSelectedSpaceIndex(newSelectedIndex);
  
      // Reinitialize fields and switches if a valid space remains
      if (newSelectedIndex !== null) {
        const selectedSpace = updatedSpaces[newSelectedIndex];
        setSpaceName(selectedSpace.spaceConfiguration.spaceName || '');
        setArea(selectedSpace.spaceConfiguration.area || '');
        setSpaceHeight(selectedSpace.spaceConfiguration.spaceHeight || '');
        setDuctworkVolume(selectedSpace.spaceConfiguration.ductworkVolume || '');
        setPlenumVolume(selectedSpace.spaceConfiguration.plenumVolume || '');
        setInstallHeight(selectedSpace.unitConfiguration.installationHeight || '');
        setReleaseOffset(selectedSpace.unitConfiguration.releaseOffset || '');
        
        // Set switch states based on the new selected space's unit configuration
        setSwitchStates({
          switch2: selectedSpace.unitConfiguration.includesRDS || false,
          switch3: selectedSpace.unitConfiguration.includesCirculation || false,
          switch4: selectedSpace.spaceConfiguration.includesAtrium || false,
          switch5: selectedSpace.spaceConfiguration.includesConnectedSpaces || false,
          switch6: selectedSpace.spaceConfiguration.includesMechVentilation || false,
          switch7: selectedSpace.spaceConfiguration.includesNaturalVentilation || false,
          switch8: selectedSpace.spaceConfiguration.includesDuctwork || false,
          switch9: selectedSpace.unitConfiguration.isDuctless || false,
        });
      } else {
        // Clear fields and switches when no rows remain
        setSpaceName('');
        setArea('');
        setSpaceHeight('');
        setDuctworkVolume('');
        setPlenumVolume('');
        setInstallHeight('');
        setReleaseOffset('');
        
        setSwitchStates({
          switch2: false,
          switch3: false,
          switch4: false,
          switch5: false,
          switch6: false,
          switch7: false,
          switch8: false,
          switch9: false,
        });
      }
  
      return updatedSpaces;
    });
  };

  //----------------------Event handler functions (implement as needed)---------------------
  const handleRefrigerantChange = (selectedRefrigerant) => {
    resetCalculation();
    setRefrigerantType(selectedRefrigerant);

    if (refrigerantData[selectedRefrigerant]) {
      const { C_LFL, F_LFL, LFL } = refrigerantData[selectedRefrigerant];
      setC_LFL(C_LFL);
      setF_LFL(F_LFL);
      setLFL(LFL);

      console.log("Refrigerant Selected:", selectedRefrigerant);
      console.log("C_LFL:", C_LFL, "F_LFL:", F_LFL, "LFL:", LFL);
    } else {
      setC_LFL(null);
      setF_LFL(null);
      setLFL(null);
      console.warn(`Refrigerant data not found for ${selectedRefrigerant}`);
    }
  };

  const handleFactoryChargeChange = (newFactoryCharge) => {
    resetCalculation();
    setFactoryCharge(newFactoryCharge);
    const updatedSysCharge = newFactoryCharge + (parseFloat(fieldCharge) || 0);
    handleSysChargeChange(updatedSysCharge);
  };

  const handleFieldChargeChange = (newFieldCharge) => {
    resetCalculation();
    setFieldCharge(newFieldCharge);
    const updatedSysCharge = (parseFloat(factoryCharge) || 0) + newFieldCharge;
    handleSysChargeChange(updatedSysCharge);
  };

  const handleSysChargeChange = (newSysCharge) => {
    setSysCharge(newSysCharge);
  /*   setSpaces((prevSpaces) => {
      const { LFL, C_LFL, F_LFL } = refrigerantData[refrigerantType] || {};
      return recalculateComplianceForAllSpaces(prevSpaces, LFL || 0, C_LFL || 0.5, F_LFL || 0);
    }); */
  };

  const handleInstallHeightChange = (value) => {
    resetCalculation();
    setInstallHeight(value);

    setSpaces((prevSpaces) =>
      prevSpaces.map((space, index) =>
        index === selectedSpaceIndex
          ? {
              ...space,
              unitConfiguration: {
                ...space.unitConfiguration,
                installationHeight: value,
              },
            }
          : space
      )
    );
  };

  const handleVentilationCFMChange = (value) => {
    resetCalculation();

    let adjustedVentilation = value;
    let showVentilationInfo = false;
    if (value > 400) {
      adjustedVentilation = 400;
      showVentilationInfo = true;
    }
    setVentilationCFM(adjustedVentilation);
  
    // Update the `ventilationCFM` value for the selected space
    setSpaces((prevSpaces) =>
      prevSpaces.map((space, index) =>
        index === selectedSpaceIndex
          ? {
              ...space,
              unitConfiguration: {
                ...space.unitConfiguration,
                ventilationCFM: adjustedVentilation,
                showVentilationInfo,
              },
            }
          : space
      )
    );
  };

  const handleReleaseOffsetChange = (value) => {
    resetCalculation();
    setReleaseOffset(value);
    //handleSpaceFieldChange("releaseOffset", value);
  };

  const handleSpaceHeightChange = (value) => {
    resetCalculation();

    let adjustedValue = value;
    let showLowInfo = false;
    let showHighInfo = false;

    // Handle edge cases for space height
    if (value < 2) {
        adjustedValue = 2;
        showLowInfo = true;
    } else if (value > 9) {
        adjustedValue = 9;
        showHighInfo = true;
    }

    setSpaceHeight(adjustedValue);

    // Update the SpaceHeight in the selected space
    setSpaces((prevSpaces) =>
        prevSpaces.map((space, index) =>
            index === selectedSpaceIndex
                ? {
                      ...space,
                      spaceConfiguration: {
                          ...space.spaceConfiguration,
                          spaceHeight: adjustedValue,
                          V_eff: calculateVeff({
                              ...space,
                              spaceConfiguration: {
                                  ...space.spaceConfiguration,
                                  spaceHeight: adjustedValue,
                              },
                          }),
                          showLowInfo,
                          showHighInfo,
                      },
                  }
                : space
        )
    );
};

  const handleToggle = (id) => {
    if (id === 'switch1') {
      // Allow switch1 (Release Mitigation) to toggle globally
      setSwitchStates((prevState) => ({
        ...prevState,
        switch1: !prevState.switch1,
      }));
      return;
    }
  
    setSpaces((prevSpaces) => {
      return prevSpaces.map((space, index) => {
        if (index === selectedSpaceIndex) {
          const updatedSpace = { ...space };

          
  
          // Map toggle IDs to `unitConfiguration` or `spaceConfiguration` fields
          const fieldMap = {
            switch2: 'includesRDS',
            switch3: 'includesCirculation',
            switch4: 'includesAtrium',
            switch5: 'includesConnectedSpaces',
            switch6: 'includesMechVentilation',
            switch7: 'includesNaturalVentilation',
            switch8: 'includesDuctwork',
            switch9: 'isDuctless',
          };
  
          const toggleField = fieldMap[id];
          if (toggleField) {
            if (id === 'switch2') {
              // Handle RDS toggle
              const newRDSState = !switches.switch2;
              updatedSpace.unitConfiguration.includesRDS = newRDSState;
            } else if (id === 'switch3') {
              resetCalculation();
              const newCirculationState = !switches.switch3;
              updatedSpace.unitConfiguration.includesCirculation = newCirculationState;
          
              // Automatically update safetyFactor
              updatedSpace.unitConfiguration.safetyFactor = newCirculationState ? 2.0 : 4.0;
              } else if (id === 'switch5') {
              // Handle Connected Spaces logic
              const newConnctedState = !switches.switch5;
              updatedSpace.spaceConfiguration.includesConnectedSpaces = newConnctedState;
              if (!newConnctedState) {
                // Turn off Ductwork if Connected Spaces is turned off
                updatedSpace.spaceConfiguration.includesDuctwork = false;
                setSwitchStates((prevState) => ({
                  ...prevState,
                  switch8: false,
                }));
              }
            } else if (id === 'switch8') {
              // Only allow Ductwork if Connected Spaces is on
              if (switches.switch5) {
                updatedSpace.spaceConfiguration.includesDuctwork = !switches.switch8;
              }
            } else {
              // General toggle handling
              updatedSpace.unitConfiguration[toggleField] = !switches[id];
            }
          }
  
          return updatedSpace;
        }
        return space;
      });
    });
  
    // Update `switchStates` to reflect changes
    setSwitchStates((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const handleRowSelection = (index) => {
    // First, save the current config
    saveCurrentSpaceConfig();
    
    setSelectedSpaceIndex(index);
    const selectedSpace = spaces[index];
  
    // Update local states
    setUnits([selectedSpace.unitConfiguration || {}]);
    setInstallHeight(selectedSpace.spaceConfiguration.installHeight || '');
    setReleaseOffset(selectedSpace.spaceConfiguration.releaseOffset || '');
    setSpaceName(selectedSpace.spaceConfiguration.spaceName || '');
    setArea(selectedSpace.spaceConfiguration.area || '');
    setSpaceHeight(selectedSpace.spaceConfiguration.spaceHeight || '');
    setDuctworkVolume(selectedSpace.spaceConfiguration.ductworkVolume || '');
    setPlenumVolume(selectedSpace.spaceConfiguration.plenumVolume || '');
    setVentilationCFM(selectedSpace.unitConfiguration.ventilationCFM || '');
    setmMax(selectedSpace.spaceConfiguration.mMax || 0);
  
    // Update toggles
    setSwitchStates((prevState) => ({
      ...prevState,
      switch2: selectedSpace.unitConfiguration.includesRDS || false,
      switch3: selectedSpace.unitConfiguration.includesCirculation || false,
      switch4: selectedSpace.spaceConfiguration.includesAtrium || false,
      switch5: selectedSpace.spaceConfiguration.includesConnectedSpaces || false,
      switch6: selectedSpace.spaceConfiguration.includesMechVentilation || false,
      switch7: selectedSpace.spaceConfiguration.includesNaturalVentilation || false,
      switch8: selectedSpace.spaceConfiguration.includesDuctwork || false,
      switch9: selectedSpace.unitConfiguration.isDuctless || false,
      // Preserve switch1 state (Release Mitigation)
      switch1: prevState.switch1,
    }));
  
    // Recalculate V_eff for the selected space and update the array
    setSpaces((prevSpaces) => {
      const updatedSpaces = prevSpaces.map((space, i) => {
        if (i === index) {
          return {
            ...space,
            spaceConfiguration: {
              ...space.spaceConfiguration,
              V_eff: calculateVeff(space),
            },
          };
        }
        return space;
      });
      return updatedSpaces;
    });

    // Automatically focus the name input if it's empty
    if (!selectedSpace.spaceConfiguration.spaceName || selectedSpace.spaceConfiguration.spaceName.trim() === '') {
      // Slight delay to ensure the DOM updates before focusing
      setTimeout(() => {
        if (nameInputRef.current) {
          nameInputRef.current.focus();
        }
      }, 0);
    }
  };

  const handleSpaceNameChange = (value) => {
    setSpaceName(value);
  
    setSpaces((prevSpaces) =>
      prevSpaces.map((space, index) =>
        index === selectedSpaceIndex
          ? {
              ...space,
              spaceConfiguration: {
                ...space.spaceConfiguration,
                spaceName: value,
              },
            }
          : space
      )
    );
  };

  const handleAreaChange = (value) => {
    resetCalculation();

    setArea(value);

    setSpaces((prevSpaces) =>
        prevSpaces.map((space, index) =>
            index === selectedSpaceIndex
                ? {
                      ...space,
                      spaceConfiguration: {
                          ...space.spaceConfiguration,
                          area: value,
                          V_eff: calculateVeff({
                              ...space,
                              spaceConfiguration: { 
                                  ...space.spaceConfiguration, 
                                  area: value 
                              },
                          }),
                      },
                  }
                : space
        )
    );
};


  const handleModalOpen = (modalId) => {
    setOpenModals((prevModals) => ({ ...prevModals, [modalId]: true }));
  };

  const handleModalClose = (modalId) => {
    setOpenModals((prevModals) => ({ ...prevModals, [modalId]: false }));
  };

  const calculateVeff = (space) => {
    const area = parseFloat(space.spaceConfiguration.area) || 0;
    const spaceHeight = parseFloat(space.spaceConfiguration.spaceHeight) || 0;
    const ductworkVolume = parseFloat(space.spaceConfiguration.ductworkVolume) || 0;
    const plenumVolume = parseFloat(space.spaceConfiguration.plenumVolume) || 0;
  
    return area * spaceHeight + (ductworkVolume) + (plenumVolume);
  };

  const handleCalculateClick = () => {
    if (allInputsValid()) {
      handleCalculate(); // Perform the calculation if inputs are valid
    } else {
      const missing = getMissingInputs();
      setMissingInputs(missing); 
      handleModalOpen('missingInputs');
    }
  };

  const handleCalculate = () => {
    if (!allInputsValid()) {
      const missing = getMissingInputs();
      setMissingInputs(missing);
      handleModalOpen('missingInputs');
      return;
    }
  
    // Calculate m_max and compliance for each space
    const updatedSpaces = spaces.map((space, index) => {
    const { spaceConfiguration, unitConfiguration } = space;
    const area = parseFloat(spaceConfiguration.area) || 0;

    // Adjust area if needed
    let adjustedArea = area;
    let showLowAreaInfo = false;
    let showHighAreaInfo = false;

    if (area < 100) {
      adjustedArea = 100;
      showLowAreaInfo = true;
    } else if (area > 1025) {
      adjustedArea = 1025;
      showHighAreaInfo = true;
    }

    // Update the `area` state for the selected space to reflect adjustedArea
    if (index === selectedSpaceIndex) {
      setArea(adjustedArea);
    }
  
    // Determine release height
    const releaseHeight = unitConfiguration.isDuctless
      ? (parseFloat(unitConfiguration.installationHeight) || 0) + (parseFloat(unitConfiguration.releaseOffset) || 0) / 12
      : parseFloat(spaceConfiguration.spaceHeight) || 0;

    // Log inputs for debugging
    console.log("area:", adjustedArea, "releaseHeight:", releaseHeight, "LFL:", LFL, "C_LFL:", C_LFL);

    // Calculate M values
    const M_A3 = ((LFL/1000) * releaseHeight * adjustedArea) / 2.0; // Formula A-3
    const M_A4 = 16.412 * Math.pow(LFL/1000, 5 / 4) * Math.sqrt(adjustedArea) * releaseHeight; // Formula A-4

    // Ensure A-4 does not exceed A-3
    if (M_A4 > M_A3) {
      console.warn(`Space ${spaceConfiguration.spaceName || 'Unnamed'}: A-4 exceeds A-3. Using A-3.`);
    }
  
    //use equation A-3 if there is Circulation
    const M = unitConfiguration.includesCirculation ? Math.min(M_A3, 35.1) : Math.min(M_A3, M_A4);

    // Calculate MV using Formula A-5
    const MV = (unitConfiguration.ventilationCFM * 4 * (LFL/1000)) / unitConfiguration.safetyFactor;

    // Final m_max calculation
    const m_max = C_LFL * (M + MV);

    // Compliance check
    const isCompliant = sysCharge <= m_max;

    console.log("M_A3:", M_A3, "M_A4:", M_A4, "M:", M, "MV:", MV, "m_max:", m_max);
  
      setCalculationMade(true); // Mark that calculation has been made
      // Return updated space
      return {
        ...space,
        spaceConfiguration: {
          ...space.spaceConfiguration,
          area: adjustedArea,
          mMax: m_max.toFixed(1), // Store m_max with 2 decimal places
          M: M.toFixed(1),
          MV: MV.toFixed(1),
          showHighAreaInfo, // Add high area info flag
          showLowAreaInfo, // Add low area info flag
        },
        unitConfiguration: {
          ...unitConfiguration,
          h_c: (releaseHeight/7.2).toFixed(2),
          isCompliant, // Update compliance status
        },
      };
    });

    if (selectedSpaceIndex !== null) {
      setmMax(updatedSpaces[selectedSpaceIndex].spaceConfiguration.mMax);
    }
  
    // Update spaces with calculated values
    setSpaces(updatedSpaces);
  
    console.log('Calculation complete. Updated spaces:', updatedSpaces);
  };

    // Function to reset calculation and isCompliant
  const resetCalculation = () => {
    setCalculationMade(false);
    setSpaces((prevSpaces) => {
      const updatedSpaces = prevSpaces.map((space) => ({
        ...space,
        unitConfiguration: {
          ...space.unitConfiguration,
          isCompliant: false, // Reset compliance
        },
        spaceConfiguration: {
          ...space.spaceConfiguration,
          m_max: 0, // Reset m_max
        },
      }));
      // Immediately update mMax for the selected space in the Space Config card
      if (selectedSpaceIndex !== null) {
        setmMax(updatedSpaces[selectedSpaceIndex].spaceConfiguration.m_max);
      }
      return updatedSpaces;
    });
  };

  const renderComplianceCheckmark = (isCompliant) => {
    if (isCompliant) {
          return (
            <span
              className="checkmark-icon complete"
            ></span>
    );
    } else {
      return (
            <span
              className="checkmark-icon incomplete"
            ></span>
      );
    }
  };

  const nameInputRef = React.useRef(null);
  
  useEffect(() => {
    // Re-evaluate allInputsValid whenever the primary inputs change
    setIsCalculateEnabled(allInputsValid());
  }, [refrigerantType, factoryCharge, spaces]);
  
  

  return (
    <div className="ASH-dashboard-container">
      {/*=========================================LEFT COLUMN=========================================================*/}
      <div className="ASH-dashboard-left">

        {/*=========================================SYS CONFIG CARD----------------------------------------------------*/}
        <div className="ASH-dashboard-card system-configuration">
          {/* System Configuration header*/}
          <h5>System Configuration</h5>

          {/* display system charge with footer label*/}
          <h1 style={{ marginTop: '0.5rem'}}>{sysCharge} lb</h1><label style={{ marginBottom: '0.5rem'}}>System charge, m<sub>s</sub></label>

          {/* Refrigerant Type Selector*/}
          <div className="dropdown-container">
          <select
            value={refrigerantType}
            onChange={(e) => {
              console.log("Dropdown Value:", e.target.value);
              handleRefrigerantChange(e.target.value);
            }}
            style={{ width: '100px' }}
          >
              <option value="" disabled selected>...</option>
              <option value="R-410A">R-410A</option>
              <option value="R-32">R-32</option>
              <option value="R-452B">R-452B</option>
              <option value="R-454A">R-454A</option>
              <option value="R-454B">R-454B</option>
              <option value="R-454C">R-454C</option>
              <option value="R-457A">R-457A</option>
            </select>
            <label> Refrigerant Type</label>  
          </div>

          {/* Factory Charge Input*/}
          <div className="charge-inputs-container">
            <input 
              type="number" 
                value={factoryCharge === 0 ? '' : factoryCharge} // Show placeholder if value is 0
                onChange={(e) => handleFactoryChargeChange(parseFloat(e.target.value) || 0)}
                placeholder="lb"  // Placeholder with unit
                required 
            />
            <label> Factory Charge</label><br /> 
          </div>

          {/* Field Charge Input*/}
          <div className="charge-inputs-container">
            <input 
              type="number" 
              value={fieldCharge === 0 ? '' : fieldCharge} // Show placeholder if value is 0 
              onChange={(e) => handleFieldChargeChange(parseFloat(e.target.value) || 0)}
              placeholder="lb"  // Placeholder with unit
            />
            <label> Field Charge</label>
          </div>

          {/* Release Mitigation Slider*/}
          <div className="toggle-container">
            <div 
              className={`toggle-switch ${switches.switch1 ? 'on' : ''}`} 
              onClick={() => handleToggle('switch1')}
            >
            <div className="toggle-thumb"></div>
            </div>
            <label className="toggle-label" style={{ marginLeft: '10px' }}> Release Mitigation</label>
          </div>
        </div>

        {/*==================================== UNIT CONFIG CARD =========================================*/}
        <div className="ASH-dashboard-card unit-configuration">
          
          {/* Indoor Unit Configuration header */}
          <h5>Unit Configuration</h5>

          {/* Indoor Unit Type toggle */}
          <div className="toggle-container">
            <div 
              className={`toggle-switch ${switches.switch9 ? 'on' : ''}`} 
              onClick={() => handleToggle('switch9')}
            >
            <div className="toggle-thumb"></div>
            </div>
            <label className="toggle-label" style={{ marginLeft: '10px' }}> Ductless</label>
          </div>

          {/* Install Height input */}
          <div className="charge-inputs-container">
            <input 
              type="number" 
              value={installHeight === 0 ? '' : installHeight} // Show placeholder if value is 0 
              onChange={(e) => handleInstallHeightChange(parseFloat(e.target.value) || 0)}
              placeholder="ft"  // Placeholder with unit
              disabled={!switches.switch9} // Disable input if Ductless is off
              className={!switches.switch9 ? 'input-disabled' : ''}
            />
            <label> Install Height</label>
          </div>

          {/* Release Offset input */}
          <div className="charge-inputs-container">
            <input 
              type="number" 
              value={releaseOffset === 0 ? '' : releaseOffset} // Show placeholder if value is 0 
              onChange={(e) => handleReleaseOffsetChange(parseFloat(e.target.value) || 0)}
              placeholder="ft"  // Placeholder with unit
              disabled={!switches.switch9} // Disable input if Ductless is off
              className={!switches.switch9 ? 'input-disabled' : ''}
            />
            <label> Release Offset</label>
          </div>

          {/* Ventilation CFM input */}
          <div className="charge-inputs-container">
            <input 
              type="number" 
              value={ventilationCFM === 0 ? '' : ventilationCFM} // Show placeholder if value is 0 
              onChange={(e) => handleVentilationCFMChange(parseFloat(e.target.value) || 0)}
              placeholder="cfm"  // Placeholder with unit
            />
            <label> Ventilation CFM</label>
            {spaces[selectedSpaceIndex]?.unitConfiguration?.showVentilationInfo && (
                  <span 
                      onClick={() => handleModalOpen('highVentilationCFM')} 
                      className="info-icon" 
                      style={{ marginLeft: '5px', color: 'white', background: 'red', fontWeight: 'bold' }}
                  >
                      &#33; {/* Info icon */}
                  </span>
              )}
          </div>

          {/* RDS Toggle */}
          <div className="toggle-container">
            <div 
              className={`toggle-switch ${switches.switch2 ? 'on' : ''}`} 
              onClick={() => handleToggle('switch2')}
            >
            <div className="toggle-thumb"></div>
            </div>
            <label className="toggle-label" style={{ marginLeft: '10px' }}> RDS</label>
          </div>

          {/* Circulation Toggle */}
          <div className="toggle-container">
            <div
              className={`toggle-switch ${switches.switch3 ? 'on' : ''}`}
              onClick={() => handleToggle('switch3')}
            >
              <div className="toggle-thumb"></div>
            </div>
            <label className="toggle-label" style={{ marginLeft: '10px' }}> Circulation</label>
            {switches.switch3 && !switches.switch2 && (
                    <span onClick={() => handleModalOpen('circulationWarning')} className="info-icon" style={{ marginLeft: '5px', color: 'white', background: 'red', fontWeight: 'bold' }}>
                        &#33; {/* Exclamation mark icon */}
                    </span>
                )}
          </div>
        </div>

        {/*==================================== SPACE CONFIG CARD =========================================*/}
        <div className="ASH-dashboard-card space-configuration">
          
          {/* Space Configuration header */}
          <h5>Space Configuration</h5>

          {/* Display m_max with footer*/}
          <h1 style={{ marginTop: '0.5rem'}}>{Number(mMax).toFixed(1)} lb</h1><label style={{ marginBottom: '0.5rem'}}>Maximum charge, m<sub>max</sub></label>

          {/* Name input */}
          <div className="charge-inputs-container">
            <input 
              type="text" 
              value={spaceName}
              onChange={(e) => {
                const value = e.target.value;
                setSpaceName(value);
                handleSpaceNameChange(value);
              }}  
              placeholder="Name"  // Placeholder with unit
              className="input" 
              required 
              ref={nameInputRef}
            />
            <label> Name</label>
          </div>

          {/* Area input */}
          <div className="charge-inputs-container">
              <input 
                  type="number" 
                  value={area}
                  onChange={(e) => {
                    const value = parseFloat(e.target.value) || 0;
                    setArea(value);
                    handleAreaChange(value);
                  }} 
                  placeholder="Area (ft2)"  // Placeholder with unit
                  className="input"
              />
              <label> Area (ft<sup>2</sup>)</label>
              {spaces[selectedSpaceIndex]?.spaceConfiguration?.showHighAreaInfo && (
                  <span 
                      onClick={() => handleModalOpen('highSpaceArea')} 
                      className="info-icon" 
                      style={{ marginLeft: '5px', color: 'white', background: 'red', fontWeight: 'bold' }}
                  >
                      &#33; {/* Info icon */}
                  </span>
              )}
                            {spaces[selectedSpaceIndex]?.spaceConfiguration?.showLowAreaInfo && (
                  <span 
                      onClick={() => handleModalOpen('lowSpaceArea')} 
                      className="info-icon" 
                      style={{ marginLeft: '5px', color: 'white', background: 'red', fontWeight: 'bold' }}
                  >
                      &#33; {/* Info icon */}
                  </span>
              )}
          </div>

          {/* Space Height input */}
          <div className="charge-inputs-container">
            <input 
                type="number" 
                value={spaceHeight}
                onChange={(e) => {
                    const value = e.target.value === '' ? '' : parseFloat(e.target.value); // Allow empty string
                    if (value !== '') {
                        handleSpaceHeightChange(value);
                    } else {
                        setSpaceHeight(value); // Allow clearing input
                    }
                }}
                placeholder="Space Height (ft)"  // Placeholder with unit
                className={switches.switch9 ? 'input-disabled' : 'input'}
                disabled={switches.switch9} // Disable if the unit is Ductless
            />
            <label> Space Height (ft)</label>
            {spaces[selectedSpaceIndex]?.spaceConfiguration?.showLowInfo && (
                <span 
                    onClick={() => handleModalOpen('lowSpaceHeight')} 
                    className="info-icon" 
                    style={{ marginLeft: '5px', color: 'white', background: 'red', fontWeight: 'bold' }}
                >
                    &#33; {/* Info icon */}
                </span>
            )}
            {spaces[selectedSpaceIndex]?.spaceConfiguration?.showHighInfo && (
                <span 
                    onClick={() => handleModalOpen('highSpaceHeight')} 
                    className="info-icon" 
                    style={{ marginLeft: '5px', color: 'white', background: 'red', fontWeight: 'bold' }}
                >
                    &#33; 
                </span>
            )}
          </div>

          {/* Connected Spaces toggle */}
          <div className="toggle-container">
              <div 
              className={`toggle-switch ${switches.switch5 ? 'on' : ''}`} 
              onClick={() => handleToggle('switch5')}
              >
              <div className="toggle-thumb"></div>
              </div>
              <label className="toggle-label" style={{ marginLeft: '10px' }}> Connected Spaces...</label>
{/*               <FaInfoCircle onClick={() => handleModalOpen('connectedSpacesInfo')} style={{ marginLeft: '5px', cursor: 'pointer' }} /> */}
          </div>

          {/* Connected by Ductwork toggle */}
{/*           <div className="toggle-container">
            <div
              className={`toggle-switch ${switches.switch8 ? 'on' : ''} ${!switches.switch5 ? 'disabled' : ''}`}
              onClick={() => switches.switch5 && handleToggle('switch8')} // Only allow toggle if Connected Spaces is on
              style={{ pointerEvents: switches.switch5 ? 'auto' : 'none' }} // Prevent interaction when disabled
            >
              <div className="toggle-thumb"></div>
            </div>
            <label className="toggle-label" style={{ marginLeft: '10px' }}> ...by Ductwork</label>
          </div> */}

          {/* Ductwork Volume Input */}
{/*           <div className="charge-inputs-container">
              <input 
                  type="number" 
                  value={ductworkVolume} 
                  onChange={(e) => {
                    const value = parseFloat(e.target.value) || 0;
                    setDuctworkVolume(value);
                    //handleSpaceFieldChange("ductworkVolume", value);
                  }}
                  placeholder="ft3"  // Placeholder with unit
                  className="input"
                  disabled={!switches.switch8} // Disable if Ductwork toggle is off
              />
              <label> Ductwork Volume (ft<sup>3</sup>)</label>
          </div> */}

          {/* Plenum Volume Input */}
{/*           <div className="charge-inputs-container">
              <input 
                  type="number" 
                  value={plenumVolume}
                  onChange={(e) => {
                    const value = parseFloat(e.target.value) || 0;
                    setPlenumVolume(value);
                    //handleSpaceFieldChange("plenumVolume", value);
                  }} 
                  placeholder="ft3"  // Placeholder with unit
                  className="input"
                  disabled={!switches.switch8} // Disable if Ductwork toggle is off
              />
              <label> Plenum Volume (ft<sup>3</sup>)</label>
          </div> */}

        </div>

      </div>

      {/*=========================================MAIN CONTENT COLUMN=========================================================*/}
      <div className="ASH-dashboard-main">

        {/*====================FLOORPLAN CARD=============================*/}
{/*         <div className="ASH-dashboard-card floorplan">
          <h5>Floorplan View</h5>
        </div> */}

        {/*====================SPACES SUMMARY TABLE=============================*/}
        <div className="ASH-dashboard-card space-summary">
          <div className="space-summary-header" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
            <h5 style={{ margin: 0, flex: '0 0 auto' }}>Spaces Summary</h5>
            <div style={{ marginLeft: 'auto' }}>
            <div className="button-container">
              {/* Add Button */}
              <button
                className="button button-add"
                onClick={addSpace}
              >
                Add Space
              </button>

              {/* Remove Button */}
              <button
                className="button button-remove"
                onClick={() => removeSpace(selectedSpaceIndex)}
                disabled={spaces.length === 0} // Disable if no spaces to remove
              >
                Remove Space
              </button>

              {/* Calculate Button */}
              <button
                className={`button button-calculate ${isCalculateEnabled ? '' : 'inactive'}`}
                onClick={handleCalculateClick}
              >
                 {calculationMade ? (
                  <>
                    <span
                    >
                      Complete
                    </span>
                  </>
                ) : (
                  'Calculate'
                )}
              </button>
            </div>
            </div>
          </div>
          <table className="space-summary-table">
          <thead>
            <tr>
              <th style={{ textAlign: 'center', width: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>Name</th>
              <th style={{ textAlign: 'center', width: '80px' }}>Area</th>
              <th style={{ textAlign: 'center', width: '100px' }}>Vol.</th>
              <th style={{ textAlign: 'center', width: '50px' }}>RDS</th>
              <th style={{ textAlign: 'center', width: '50px' }}><FaWind /></th>
              <th style={{ textAlign: 'center', width: '80px' }}>m<sub>s</sub></th>
              <th style={{ textAlign: 'center', width: '80px' }}>m<sub>rel</sub></th>
              <th style={{ textAlign: 'center', width: '80px' }}>m<sub>max</sub></th>
              <th style={{ textAlign: 'center', width: '80px' }}>C</th>
              <th style={{ textAlign: 'center', width: '80px' }}>M</th>
              <th style={{ textAlign: 'center', width: '80px' }}>M<sub>V</sub></th>
              <th style={{ textAlign: 'center', width: '100px' }}>h<sub>c</sub></th>
              <th style={{ textAlign: 'center', width: '80px' }}>Pass?</th>
            </tr>
          </thead>
          <tbody>
            {spaces.map((space, index) => (
                <tr
                  key={index}
                  onClick={() => handleRowSelection(index)}
                  className={selectedSpaceIndex === index ? 'selected' : ''}
                >
                <td style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '150px' }}>{space.spaceConfiguration.spaceName}</td>
                <td>
                  {space.spaceConfiguration.area}
                  {space.spaceConfiguration.showHighAreaInfo && (
                  <span 
                      onClick={() => handleModalOpen('highSpaceArea')} 
                      className="info-icon" 
                      style={{ marginLeft: '5px', color: 'white', background: 'red', fontWeight: 'bold' }}
                  >
                      &#33; {/* Info icon */}
                  </span>
                  )}
                  {space.spaceConfiguration.showLowAreaInfo && (
                      <span 
                          onClick={() => handleModalOpen('lowSpaceArea')} 
                          className="info-icon" 
                          style={{ marginLeft: '5px', color: 'white', background: 'red', fontWeight: 'bold' }}
                      >
                          &#33; {/* Info icon */}
                      </span>
                  )}
                </td>
                <td>{Number(space.spaceConfiguration.V_eff || 0).toLocaleString(undefined, { maximumFractionDigits: 0 })}</td>
                <td>{space.unitConfiguration.includesRDS ? <FaCheck /> : '-'}</td>
                <td>{space.unitConfiguration.includesCirculation ? <FaCheck /> : '-'}</td>
                <td>{sysCharge}</td>
                <td>{Number(space.spaceConfiguration.EDVC || 0).toFixed(2)}</td>
                <td>{space.spaceConfiguration.mMax}</td>
                <td>{C_LFL}</td>
                <td>{space.spaceConfiguration.M}</td>
                <td>{space.spaceConfiguration.MV}</td>
                <td>{space.unitConfiguration.h_c}</td>
                <td>{space.unitConfiguration.isCompliant ? renderComplianceCheckmark(space.unitConfiguration.isCompliant) : '-'}</td>
              </tr>
            ))}
          </tbody>
          </table>
        </div>

      </div>

      {/* Info Modals */}

      {openModals.installHeightInfo && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={() => handleModalClose('installHeightInfo')}>&times;</span>
            <p>Installation height refers to the height at which the unit is installed, measured from the ground.</p>
          </div>
        </div>
      )}

      {openModals.releaseOffsetInfo && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={() => handleModalClose('releaseOffsetInfo')}>&times;</span>
            <p>The <b>release offset</b> refers to the distance from the bottom of the unit to lowest opening point which is large enough to leak refrigerant.</p>
          </div>
        </div>
      )}

      {openModals.atriumInfo && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={() => handleModalClose('atriumInfo')}>&times;</span>
            <p>Where different stories and floor levels connect through an open <b>atrium</b> or mezzanine, the <i>effective dispersal volume</i> shall be determined by multiplying the floor area of the lowest floor level by 8.2 ft (2.5 m) ceiling height.</p>
          </div>
        </div>
      )}

      {openModals.connectedSpacesInfo && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={() => handleModalClose('connectedSpacesInfo')}>&times;</span>
            <p>Connected spaces details...</p>
          </div>
        </div>
      )}

      {openModals.circulationWarning && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={() => handleModalClose('circulationWarning')}>&times;</span>
            <p>Applying <i>Circulation</i> which is not activated by an RDS requires the <i>circulation</i> to operate continuosly.</p>
            <p>Refer to ASHRAE 15.2-2024, Section 9.4.2.1 and 9.5</p>
          </div>
        </div>
      )}

      {openModals.lowSpaceHeight && (
        <div className="modal">
          <div className="modal-content">
            <p>You've entered a space height less than 2 ft.</p>
            <p>The resulting m<sub>max</sub> value is based on a 2 foot space height.</p>
            <p>Refer to ASHRAE 15.2-2024, Table 9-3 footnote a.</p>
            <button onClick={() => handleModalClose('lowSpaceHeight')}>Close</button>
          </div>
        </div>
      )}

    {openModals.highSpaceHeight && (
        <div className="modal">
          <div className="modal-content">
            <p>You've entered a space height greater than 9 ft.</p>
            <p>The resulting m<sub>max</sub> value is based on a 9 foot space height.</p>
            <p>Refer to ASHRAE 15.2-2024, Table 9-3 footnote a.</p>
            <button onClick={() => handleModalClose('highSpaceHeight')}>Close</button>
          </div>
        </div>
      )}

    {openModals.highSpaceArea && (
      <div className="modal">
        <div className="modal-content">
          <p>You've entered a space area greater than 1,025 ft<sup>2</sup>.</p>
          <p>The resulting m<sub>max</sub> value is based on a 1,025 ft<sup>2</sup> space area.</p>
          <p>Refer to ASHRAE 15.2-2024, Table 9-3 footnote c.</p>
          <button onClick={() => handleModalClose('highSpaceArea')}>Close</button>
        </div>
      </div>
    )}

    {openModals.lowSpaceArea && (
      <div className="modal">
        <div className="modal-content">
          <p>You've entered a space area less than 100 ft<sup>2</sup>.</p>
          <p>The resulting m<sub>max</sub> value is based on a 100 ft<sup>2</sup> space area.</p>
          <p>Refer to ASHRAE 15.2-2024, Table 9-3 footnote c.</p>
          <button onClick={() => handleModalClose('lowSpaceArea')}>Close</button>
        </div>
      </div>
    )}

    {openModals.highVentilationCFM && (
      <div className="modal">
        <div className="modal-content">
          <p>You've entered a ventilation rate higher than 400 CFM.</p>
          <p>The resulting MV value is based on a 400 CFM ventiation airflow volume.</p>
          <p>Refer to ASHRAE 15.2-2024, Table 9-4.</p>
          <button onClick={() => handleModalClose('highVentilationCFM')}>Close</button>
        </div>
      </div>
    )}

      {openModals.missingInputs && (
        <div className="modal">
          <div className="modal-content">
            <h2>Complete the Missing Inputs</h2>
            <p>Complete the missing inputs in order to submit:</p>
            <div>
              {missingInputs.map((input, index) => (
                input === "<hr />" ? (
                  <hr key={index} />
                ) : (
                  <p key={index}>{input}</p>
                )
              ))}
            </div>
            <button onClick={() => handleModalClose('missingInputs')}>Close</button>
          </div>
        </div>
      )}

      {openModals.resetCalc && (
        <div className="modal">
          <div className="modal-content">
            <p>Editing this input will clear the calculation results.  Are you sure you want to proceed?</p>
            <button onClick={() => handleModalClose('resetCalc')}>Close</button>
          </div>
        </div>
      )}         

    </div>
  );
};

export default Ashrae152;
