import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import Login from './Login';
import Home from './Home';
import Dashboard from './Dashboard';
import Register from './Register';
import Admin from './Admin';
import Ashrae152 from './Ashrae152';
import Ashrae15 from './Ashrae15';
import MyAccount from './MyAccount';
import Header from './Header';
import Footer from './Footer';
import Wiki from './wiki';
import PrivacyPolicy from './PrivacyPolicy';
import Sidebar from './Sidebar';
import ProtectedRoute from './ProtectedRoute';
import './styles.css';

const App = () => {
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const navigate = useNavigate(); // Correct usage of useNavigate inside the component

  useEffect(() => {
    // Check if user is logged in by checking for a token in local storage
    const token = localStorage.getItem('token');
    if (token) {
      setLoggedIn(true);
      console.log("LoggedIn = true")
    } else {
      setLoggedIn(false);
      console.log("LoggedIn=false")
    }
  }, []);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const handleLogout = () => {
    localStorage.clear(); // Clear all localStorage items
    setLoggedIn(false); // Update the state to reflect the user is logged out
    navigate('/login'); // Navigate back to login page
  };  

  return (
      <div className={`app ${isSidebarOpen ? 'sidebar-open' : 'sidebar-collapsed'}`}>
        <Sidebar
          key={isLoggedIn ? 'loggedIn' : 'loggedOut'} // Key forces re-render on login/logout 
          isOpen={isSidebarOpen} 
          toggleSidebar={toggleSidebar} 
          isLoggedIn={isLoggedIn} 
          onLogout={handleLogout}
        />
        <button className="menu-btn" onClick={toggleSidebar}>☰</button>
        <div className="page-wrap">
          <Header isLoggedIn={isLoggedIn} onLogout={handleLogout}/>
          <main className="main-content">
            <Routes>
                <Route path="/login" element={<Login setLoggedIn={setLoggedIn} />} />
                <Route path="/register" element={<Register />} />
                <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
                <Route element={<ProtectedRoute />}>
                  <Route path="/" element={<Home />} />
                  <Route path="/dashboard" element={<Dashboard onLogout={handleLogout}/>} />
                  <Route path="/Wiki" element={<Wiki />} />
                  <Route path="/Ashrae152" element={<Ashrae152 />} />
                  <Route path="/Ashrae15" element={<Ashrae15 />} />
                  <Route path="/MyAccount" element={<MyAccount />} />
                </Route>
                <Route element={<ProtectedRoute requireAdmin={true} />}>
                  <Route path="/Admin" element={<Admin />} />
                </Route>
              </Routes>
          </main>
          <Footer />
        </div>
      </div>
  );
};

const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;


