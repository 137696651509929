import React, { useState, useEffect } from 'react';
import axios from 'axios';


const MyAccount = ({ onLogout }) => {
  const [user, setUser] = useState({ username: '', isAdmin: '', createdAt: '' });
  const [showModal, setShowModal] = useState(false);

  const handleDeleteUser = async () => {
    try {
      const email = user.username;
      await axios.delete(`/api/deleteUser?email=${email}`);
      localStorage.clear(); // Clear local storage
      onLogout(); // Execute logout function
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };

  useEffect(() => {
    // Retrieve user details from localStorage
    const storedUser = {
      username: localStorage.getItem('user_email') || 'Unknown User', // Using email as username
      isAdmin: localStorage.getItem('is_admin') === '1', // Convert string to boolean
      createdAt: localStorage.getItem('created_at') || '', // Ensure this key exists in localStorage
    };
    
    setUser(storedUser);
  }, []);

  return (
    <>
      <div className="admin-content">
        <h1>My Account</h1>
        <hr className="account-divider" />
        <p> 
          <strong>Username:</strong> {user.username}
          <br />
          <strong>Admin Status:</strong> {user.isAdmin ? 'Yes' : 'No'}
          <br />
          <strong>Account Created:</strong> {new Date(user.createdAt).toLocaleDateString()}
        </p>
        <button onClick={() => setShowModal(true)} className="button">Delete User</button>

      </div>
      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <h2>Warning!</h2>
            <p>Are you sure you want to delete your account? This action cannot be undone.</p>
            <div className="modal-buttons">
              <button onClick={() => setShowModal(false)} className="button">Cancel</button>
              <button onClick={handleDeleteUser} className="button" style={{ backgroundColor: 'red', color: 'white' }}>Delete</button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MyAccount;

