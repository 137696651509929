// src/wiki.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './styles.css';

const Wiki = ({ onLogout }) => {
  const navigate = useNavigate();
  const isAdmin = localStorage.getItem('is_admin') === '1'; // Check admin status
  const email = localStorage.getItem('user_email');

  const handleAdminButtonClick = () => {
    navigate('/admin');
  };

  const handleHomeButtonClick = () => {
    navigate('/'); // Navigate to the root URL
  };

  return (
    <div className="wiki-container">
      {/* Hero Section */}
      <div className="hero-section">
        <h1>Welcome to the RCLcheck Wiki</h1>
        <p>Your guide to refrigerant safety and compliance.</p>
      </div>

      {/* Why Use RCLcheck Section */}
      <div className="content-section">
        <h2 className="section-title">Why Use RCLcheck?</h2>
        <div className="benefits-list">
          <div className="benefit">
            <h3>Accurate Calculations</h3>
            <p>Get reliable refrigerant concentration calculations aligned with the latest ASHRAE standards.</p>
          </div>
          <div className="benefit">
            <h3>Knowledge Base</h3>
            <p>Access detailed information and resources on refrigerant safety and best practices.</p>
          </div>
          <div className="benefit">
            <h3>Updated Information</h3>
            <p>Stay current with evolving standards and industry guidelines for maximum compliance.</p>
          </div>
        </div>
      </div>

      {/* How It Works Section */}
      <div className="color-block">
        <h2 className="section-title">How It Works</h2>
        <div className="content-section">
          <p>1. <strong>Explore Topics</strong>: Browse through different refrigerant safety topics and ASHRAE guidelines.</p>
          <p>2. <strong>Learn the Details</strong>: Dive deep into the technical details and safety measures.</p>
          <p>3. <strong>Implement Best Practices</strong>: Use this knowledge to ensure your systems are compliant.</p>
        </div>
      </div>

      {/* Admin Section */}
      {isAdmin && (
        <div className="content-section">
          <button onClick={handleAdminButtonClick}>Go to Admin Page</button>
        </div>
      )}

      {/* Navigation Buttons */}
      <div className="content-section">
        <button onClick={handleHomeButtonClick}>Go to Home Page</button>
        <button onClick={onLogout} style={{ marginTop: '20px' }}>Logout</button>
      </div>
    </div>
  );
};

export default Wiki;
