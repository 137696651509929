// src/home.js
import { useNavigate } from 'react-router-dom';
import './styles.css';
import heroImage from './assets/images/ian-simmonds-3mW0eQNcfSE-unsplash_cropped.png';
import processImage from './assets/images/home_process.png';
import houseImage from './assets/images/home_house.png';

const Home = () => {
  const navigate = useNavigate();
  const userEmail = localStorage.getItem('user_email');

/*   const handleDashboardClick = () => {
    navigate('/dashboard');
  }; */

  return (
    <main className="hero-container">
      {/* Hero Section */}
      
      <section className="hero-section">
        <div className="hero-sub-sections">
          <div className="hero-sub-section-left">
            <div className='hero-content'><h2>Fast, Easy & Accurate</h2></div>
            <div className='hero-content'><h1>Refrigerant Charge Calculations</h1></div>
            <div className='hero-content'>
              <p>
              The transition to A2L refrigerants introduces new challenges for architects, engineers, contractors, and code officials. Ensuring compliance with ASHRAE 15 and ASHRAE 15.2 can be complex—but it doesn’t have to be.
              </p>              
              <p>
              <b>RCLcheck.com</b> simplifies the process by handling the intricate calculations for you, streamlining compliance checks during the design phase. 
              </p>
            </div>
            <div className='hero-content'>
              <p>Save time and calculate with confidence!</p>
            </div>
            <div className='get-started-button-container'>
              <a href="#Ashrae152.js" className="get-started-button">Get Started <i class="fas fa-arrow-right"></i></a>
            </div>
          </div>
          <div className="hero-sub-section-right">
            <img src={heroImage} alt="A high rise office building" />
          </div>
        </div>
      </section>

{/*       <div className="villain-section">
        <div><h3>Results You Can Rely On</h3></div>
        <div className="villain-deck">
          <div className="villain-cards">
            <div><h4>For Pros, By Pros</h4></div>
            <div><img src={houseImage} alt="Line connected process dots" /></div>
            <div><h5>Developed by industry professionals with over 40 years of HVAC industry experience.</h5></div>
          </div>
          <div className="villain-cards">
            <div><h4>Calculation Tracing</h4></div>
            <div><img src={houseImage} alt="Line connected process dots" /></div>
            <div><h5>All calculation results include table references & section references for easy verification of calculations.</h5></div>
          </div>
          <div className="villain-cards">
            <div><h4>Up-to-Date</h4></div>
            <div><img src={houseImage} alt="Line connected process dots" /></div>
            <div><h5>Knowledge bases and standards are kept up-to-date with all the latest addenda, errata, and formal interpretations.</h5></div>
          </div>
        </div>
      </div> */}

{/*       <div className="villain-section">
        <div className="villain-deck-dark">
          <a href="ashrae152">
            <div className="villain-cards-dark">
              <div><h4>Residential</h4></div>
              <div><img src={houseImage} alt="Line connected process dots" /></div>
              <div><h5>Residential calculations apply the latest edition of ASRHAE 15.2</h5></div>
              <div className='villain-go-button.container'>
                <a href="ashrae152" className="villain-go-button">Go <i class="fas fa-arrow-right"></i></a>
              </div>
            </div>
          </a>
          <a href="ashrae15">
            <div className="villain-cards-dark">
              <div><h4>Commercial</h4></div>
              <div><img src={houseImage} alt="Line connected process dots" /></div>
              <div><h5>Commercial calculations apply the latest edition of ASRHAE 15</h5></div>
              <div className='villain-go-button.container'>
                <a href="Ashrae15" className="villain-go-button">Go <i class="fas fa-arrow-right"></i></a>
              </div>
            </div>
          </a>
        </div>
      </div> */}

{/*       <div className="wiki-section">
        <div><h3>Maybe something to promote the Wiki/Knowledge Base?</h3></div>
      </div> */}

{/*       <footer className="footer-section">
        <div className="left-content">
          <a href="#">© Mobius Technologies</a>
          <a href="#">Privacy Policy</a>
          <a href="#">Terms of Service</a>
        </div>
        <div className="right-content">
          <a href="#"><i className="fab fa-facebook-f"></i></a>
          <a href="#"><i className="fab fa-twitter"></i></a>
          <a href="#"><i className="fab fa-linkedin-in"></i></a>
          <a href="#"><i className="fab fa-instagram"></i></a>
        </div>
      </footer> */}

    </main>
  );
};

export default Home;