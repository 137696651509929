import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Ashrae15.css';
import './styles.css';
import { FaInfoCircle, FaCheck } from 'react-icons/fa'; // Import info icon

const Ashrae15 = () => {
  
  const navigate = useNavigate();
  const [units, setUnits] = useState([{
    tag: '',
    unitType: '',
    roomArea: '',
    installationHeight: '',
    releaseOffset: '',
    ceilingHeight: '',
    liquidPipeDiameter: '',
    liquidPipeLength: '',
    suctionPipeDiameter: '',
    suctionPipeLength: '',
    indoorCoilVolume: '',
    includesRDS: false,
    includesCirculation: false
  }]); // Default to 1 unit on page load  
  const [isSwitchOn, setIsSwitchOn] = useState(false); // Toggle state
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openModals, setOpenModals] = useState({}); // Track which modal is open for each unit
  const [error, setError] = useState(''); // To display error messages
  const [factoryCharge, setFactoryCharge] = useState(0); // Mandatory field initialized to 0
  const [fieldCharge, setFieldCharge] = useState(0);     // Optional field initialized to 0
  const [refrigerantType, setRefrigerantType] = useState(''); // Track refrigerant type
  const refrigerantData = {
    "R-410A": { C_LFL: "-", F_LFL: "-", LFL: "-" }, //LFL in lb/1000 ft^3
    "R-32":   { C_LFL: 1.0, F_LFL: 1.0, LFL: 19.1 },
    "R-452B": { C_LFL: 1.02, F_LFL: 1.02, LFL: 19.3 },
    "R-454A": { C_LFL: 0.92, F_LFL: 0.92, LFL: 18.3 },
    "R-454B": { C_LFL: 0.97, F_LFL: 0.97, LFL: 22.0 },
    "R-454C": { C_LFL: 0.95, F_LFL: 0.95, LFL: 18.0 },
    "R-457A": { C_LFL: 0.71, F_LFL: 0.71, LFL: 13.5 },
  };
  const [C_LFL, setC_LFL] = useState(null);
  const [F_LFL, setF_LFL] = useState(null);
  const [LFL, setLFL] = useState(null);  
  const [installHeight, setInstallHeight] = useState(''); // Track install height
  const [releaseOffset, setReleaseOffset] = useState(''); // Track release offset
  const [spaceName, setSpaceName] = useState(''); // Track space name
  const [area, setArea] = useState(''); // Track area
  const [spaceHeight, setSpaceHeight] = useState(''); // Track space height
  const [ductworkVolume, setDuctworkVolume] = useState(''); // Track ductwork volume
  const [plenumVolume, setPlenumVolume] = useState(''); // Track plenum volume
  const [edvc, setEdvc] = useState(0); // Effective Dispersal Volume Charge initialized to 0
  const [sysCharge, setSysCharge] = useState(0);
  const [switches, setSwitchStates] = useState({
    switch1: false,
    switch2: false,
    switch3: false,
    switch4: false,
    switch5: false,
    switch6: false,
    switch7: false,
    switch8: false,
  });
  const [selectedSpaceIndex, setSelectedSpaceIndex] = useState(0); // Selected row index
  const [spaces, setSpaces] = useState([
    {
      tag: "Default Space", // Provide a default tag
      unitConfiguration: {
        tag: "Default Unit",
        unitType: "",
        roomArea: "",
        installationHeight: 0,
        releaseOffset: 0,
        ceilingHeight: 0,
        liquidPipeDiameter: 0,
        liquidPipeLength: 0,
        suctionPipeDiameter: 0,
        suctionPipeLength: 0,
        indoorCoilVolume: 0,
        includesRDS: false,
        includesCirculation: false,
        M_def: 0,
        isCompliant: false,
      },
      spaceConfiguration: {
        spaceName: "",
        area: 0,
        spaceHeight: 0,
        V_eff: 0,
        isAtrium: false,
        EDVC: 0,
        isConnected: false,
        includesMechVentilation: false,
        includesNaturalVentilation: false,
        includesDuctwork:false,
        ductworkVolume: "",
        plenumVolume: "",
        F_occ: 1,
      },
    },  
  ]); // Array of space objects to store each space's configuration

  const saveCurrentSpaceConfig = () => {
    if (selectedSpaceIndex !== null && selectedSpaceIndex >= 0) {
      setSpaces((prevSpaces) => {
        const updatedSpaces = [...prevSpaces];
        const currentSpace = updatedSpaces[selectedSpaceIndex];
  
        // Preserve the existing V_eff
        const currentVeff = currentSpace.spaceConfiguration.V_eff;
        const currentEDVC = currentSpace.spaceConfiguration.EDVC;
        const currentM_def= currentSpace.unitConfiguration.M_def;
  
        updatedSpaces[selectedSpaceIndex] = {
          ...currentSpace,
          unitConfiguration: {
            ...currentSpace.unitConfiguration,
            M_def: currentM_def,
          },
          spaceConfiguration: {
            ...currentSpace.spaceConfiguration,
            installHeight,
            releaseOffset,
            spaceName,
            area,
            spaceHeight,
            ductworkVolume,
            plenumVolume,
            V_eff: currentVeff, // Preserve V_eff instead of resetting it
            EDVC: currentEDVC,
          },
        };
        return updatedSpaces;
      });
    }
  };
  
  const addSpace = () => {
    const newSpace = {
      unitConfiguration: {
        tag: '',
        unitType: '',
        roomArea: '',
        installationHeight: '',
        releaseOffset: '',
        ceilingHeight: '',
        liquidPipeDiameter: '',
        liquidPipeLength: '',
        suctionPipeDiameter: '',
        suctionPipeLength: '',
        indoorCoilVolume: '',
        includesRDS: false,
        includesCirculation: false,
      },
      spaceConfiguration: {
        spaceName: '',
        factoryCharge: '',
        fieldCharge: '',
        refrigerantType: '',
        // Add any additional properties as needed
      }
    };
    setSpaces((prevSpaces) => {
      const updatedSpaces = [...prevSpaces, newSpace];
      // Select the newly added row (which will be at the last index)
      setSelectedSpaceIndex(updatedSpaces.length - 1);
      setTimeout(() => {
        if (nameInputRef.current) {
          nameInputRef.current.focus();
        }
      }, 0);
      return updatedSpaces;
    });

    // Reset toggles for RDS and Circulation
    setSwitchStates((prevSwitches) => ({
      ...prevSwitches,
      switch2: false, // includesRDS
      switch3: false, // includesCirculation
    }));

    //Clear input fields
    setInstallHeight('');
    setReleaseOffset('');
    setSpaceName('');
    setArea('');
    setSpaceHeight('');
    setDuctworkVolume('');
    setPlenumVolume('');
  };

  // Function to remove a space
  const removeSpace = (indexToRemove) => {
    if (spaces.length === 1) {
      alert("You must have at least one space.");
      return;
    }
    setSpaces((prevSpaces) => {
      const updatedSpaces = prevSpaces.filter((_, index) => index !== indexToRemove);
  
      // Determine the new selected index
      const newSelectedIndex = updatedSpaces.length > 0
        ? Math.min(indexToRemove, updatedSpaces.length - 1) // Select the next closest row
        : null;
  
      setSelectedSpaceIndex(newSelectedIndex);
  
      // Reinitialize fields and switches if a valid space remains
      if (newSelectedIndex !== null) {
        const selectedSpace = updatedSpaces[newSelectedIndex];
        setSpaceName(selectedSpace.spaceConfiguration.spaceName || '');
        setArea(selectedSpace.spaceConfiguration.area || '');
        setSpaceHeight(selectedSpace.spaceConfiguration.spaceHeight || '');
        setDuctworkVolume(selectedSpace.spaceConfiguration.ductworkVolume || '');
        setPlenumVolume(selectedSpace.spaceConfiguration.plenumVolume || '');
        setInstallHeight(selectedSpace.unitConfiguration.installationHeight || '');
        setReleaseOffset(selectedSpace.unitConfiguration.releaseOffset || '');
        
        // Set switch states based on the new selected space's unit configuration
        setSwitchStates({
          switch2: selectedSpace.unitConfiguration.includesRDS || false,
          switch3: selectedSpace.unitConfiguration.includesCirculation || false,
          switch4: selectedSpace.spaceConfiguration.includesAtrium || false,
          switch5: selectedSpace.spaceConfiguration.includesConnectedSpaces || false,
          switch6: selectedSpace.spaceConfiguration.includesMechVentilation || false,
          switch7: selectedSpace.spaceConfiguration.includesNaturalVentilation || false,
          switch8: selectedSpace.spaceConfiguration.includesDuctwork || false,
        });
      } else {
        // Clear fields and switches when no rows remain
        setSpaceName('');
        setArea('');
        setSpaceHeight('');
        setDuctworkVolume('');
        setPlenumVolume('');
        setInstallHeight('');
        setReleaseOffset('');
        
        setSwitchStates({
          switch2: false,
          switch3: false,
          switch4: false,
          switch5: false,
          switch6: false,
          switch7: false,
          switch8: false,
        });
      }
  
      return updatedSpaces;
    });
  };


  const nameInputRef = React.useRef(null);

  const table71 = [
    { floorArea: 50, heights: [4.0, 4.0, 4.0, 4.0, 4.0, 4.0, 4.3] },
    { floorArea: 100, heights: [4.0, 4.4, 5.6, 6.3, 6.9, 7.7, 8.6] },
    { floorArea: 125, heights: [4.0, 4.5, 5.5, 7.0, 7.8, 8.6, 9.6] },
    { floorArea: 150, heights: [4.0, 4.6, 5.7, 7.8, 8.6, 9.4, 10.5] },
    { floorArea: 175, heights: [4.0, 5.1, 7.1, 9.1, 10.1, 11.1, 12.4] },
    { floorArea: 200, heights: [4.0, 5.7, 8.0, 10.1, 11.3, 12.4, 13.8] },
    { floorArea: 225, heights: [4.0, 6.3, 8.9, 11.4, 12.6, 13.8, 15.7] },
    { floorArea: 250, heights: [4.0, 6.9, 9.9, 12.6, 13.9, 15.2, 17.2] },
    { floorArea: 300, heights: [4.0, 7.7, 11.2, 14.1, 15.6, 17.1, 19.4] },
    { floorArea: 350, heights: [4.3, 7.1, 10.0, 14.3, 15.7, 17.5, 20.2] },
    { floorArea: 400, heights: [4.6, 7.6, 10.7, 17.0, 18.4, 20.3, 23.2] },
    { floorArea: 450, heights: [5.1, 8.5, 11.9, 17.8, 18.8, 20.9, 24.5] },
    { floorArea: 500, heights: [5.1, 8.5, 11.9, 17.1, 18.8, 20.9, 24.5] },
    { floorArea: 600, heights: [6.0, 10.0, 14.0, 19.7, 21.6, 23.8, 28.1] },
    { floorArea: 700, heights: [6.5, 10.8, 15.1, 21.6, 23.7, 26.1, 30.9] },
    { floorArea: 800, heights: [6.9, 11.4, 16.0, 22.9, 25.2, 28.0, 31.4] },
    { floorArea: 900, heights: [7.2, 12.1, 16.9, 24.1, 26.5, 29.6, 33.1] },
    { floorArea: 1000, heights: [7.9, 13.2, 18.5, 25.8, 28.4, 31.8, 35.5] },
    { floorArea: 1200, heights: [8.6, 14.3, 20.0, 27.5, 30.3, 34.0, 38.2] },
    { floorArea: 1400, heights: [9.2, 15.3, 21.4, 29.0, 32.1, 36.0, 40.7] },
    { floorArea: 1600, heights: [9.7, 16.2, 22.7, 30.7, 34.0, 37.9, 43.3] },
    { floorArea: 1800, heights: [10.2, 17.1, 23.9, 30.7, 34.1, 37.5, 41.8] },
    { floorArea: 2250, heights: [10.9, 18.1, 25.3, 32.6, 36.2, 39.8, 46.8] },
    { floorArea: 2750, heights: [12.0, 20.0, 28.0, 36.0, 40.0, 44.0, 49.0] },
    { floorArea: 3250, heights: [12.5, 21.0, 29.4, 37.6, 41.8, 46.0, 51.5] },
    { floorArea: 3500, heights: [13.5, 22.5, 31.6, 40.6, 45.1, 49.7, 55.3] },
    { floorArea: 4000, heights: [14.5, 24.1, 33.8, 43.4, 48.3, 53.1, 59.1] },
    { floorArea: 4500, heights: [15.4, 25.5, 36.1, 46.1, 51.6, 56.6, 62.8] },
    { floorArea: 5000, heights: [16.2, 27.0, 37.8, 48.6, 54.0, 59.4, 66.1] },
    { floorArea: 6000, heights: [17.7, 29.6, 41.4, 53.2, 59.1, 65.0, 72.4] },
    { floorArea: 7000, heights: [19.2, 31.9, 44.7, 57.4, 63.9, 70.2, 78.2] },
    { floorArea: 8000, heights: [20.5, 34.1, 47.8, 61.4, 68.3, 75.1, 83.6] },
    { floorArea: 10000, heights: [22.9, 38.0, 53.1, 68.2, 75.8, 83.9, 93.5] },
    { floorArea: 15000, heights: [28.0, 46.7, 65.4, 84.1, 93.5, 102.8, 114.5] },
    { floorArea: 20000, heights: [34.4, 57.4, 80.4, 103.7, 115.7, 128.2, 142.8] },
    { floorArea: 25000, heights: [36.2, 60.3, 84.5, 108.6, 120.7, 132.7, 147.5] },
    { floorArea: 28000, heights: [38.3, 63.8, 89.4, 114.9, 127.7, 140.5, 156.4] },
  ];

  // Update Space Height if Atrium toggle is on
  useEffect(() => {
    if (switches.switch4) {
      setSpaceHeight('8.6');
    }
  }, [switches.switch4]);

  // Update Mech Ventilation, Natural Ventilation, and By Ductwork if Connected Spaces is toggled off
  useEffect(() => {
    if (!switches.switch5) {
      setSwitchStates((prevState) => ({
        ...prevState,
        switch6: false,
        switch7: false,
        switch8: false,
      }));
    }
  }, [switches.switch5]);

  useEffect(() => {
    const initialSpaces = spaces.map((space) => ({
      ...space,
      V_eff: calculateVeff(space),
    }));
    setSpaces(initialSpaces);
  }, []);

  useEffect(() => {
    if (selectedSpaceIndex !== null) {
      const selectedSpace = spaces[selectedSpaceIndex];
      setEdvc(selectedSpace.spaceConfiguration.EDVC || 0);
    }
  }, [selectedSpaceIndex, spaces]);
  
  useEffect(() => {
    const updatedSysCharge = (parseFloat(factoryCharge) || 0) + (parseFloat(fieldCharge) || 0);
    setSysCharge(updatedSysCharge); // Update sysCharge state
    handleSysChargeChange(updatedSysCharge); // Recalculate compliance
  }, [factoryCharge, fieldCharge]);

  function lookupRefrigerantCharge(floorArea, height) {
    if (height === 0) {
      console.warn("Height is zero; check installationHeight and releaseOffset.");
    }
  
    console.log("lookupRefrigerantCharge inputs:", { floorArea, height });
  
    const closestRow = table71.find((row) => row.floorArea >= floorArea);
    if (!closestRow) {
      console.warn("No matching row found for floorArea:", floorArea);
      return null;
    }
  
    const heightIndex = getHeightIndex(height);
    if (heightIndex === undefined) {
      console.warn("Invalid height for lookup:", height);
      return null;
    }
  
    const charge = closestRow.heights[heightIndex];
    console.log("Calculated M_def (charge):", charge);
  
    return charge;
  }
    
  function getHeightIndex(height) {
    if (height <= 2.0) return 0;
    else if (height <= 3.3) return 1;
    else if (height <= 4.6) return 2;
    else if (height <= 5.9) return 3;
    else if (height <= 6.6) return 4;
    else if (height <= 7.2) return 5;
    else if (height >= 9.0) return 6;
  
    // Add a fallback for invalid heights
    console.warn("Height out of range:", height);
    return undefined;
  }

 //----------------------Event handler functions (implement as needed)---------------------
 const handleRefrigerantChange = (event) => {
  const selectedRefrigerant = event.target.value;
  setRefrigerantType(selectedRefrigerant);

    if (refrigerantData[selectedRefrigerant]) {
      const { C_LFL, F_LFL, LFL } = refrigerantData[selectedRefrigerant];
      setC_LFL(C_LFL);
      setF_LFL(F_LFL);
      setLFL(LFL);

      // Update EDVC for all spaces
      setSpaces((prevSpaces) =>
        prevSpaces.map((space) => {
          const newEDVC = calculateEDVC(space, LFL, 0.5, F_LFL);
          return {
            ...space,
            spaceConfiguration: {
              ...space.spaceConfiguration,
              LFL,
              C_LFL,
              F_LFL,
              EDVC: newEDVC.toFixed(1), // Round EDVC to 1 decimal place
            },
          };
        })
      );
    } else {
      setC_LFL(null);
      setF_LFL(null);
      setLFL(null);
    }
  };

  const handleModalOpen = (modalId) => {
    setOpenModals((prevModals) => ({ ...prevModals, [modalId]: true }));
  };

  const handleModalClose = (modalId) => {
    setOpenModals((prevModals) => ({ ...prevModals, [modalId]: false }));
  };

  const handleToggle = (id) => {
    if (id === 'switch1') {
      // Allow switch1 (Release Mitigation) to toggle globally
      setSwitchStates((prevState) => ({
        ...prevState,
        switch1: !prevState.switch1,
      }));
      return;
    }
    setSpaces((prevSpaces) => {
      const updatedSpaces = prevSpaces.map((space, index) => {
        if (index === selectedSpaceIndex) {
          const updatedSpace = { ...space };
  
          if (id === 'switch5' && switches.switch5) {
            // If turning off Connected Spaces, turn off related toggles
            updatedSpace.spaceConfiguration.includesConnectedSpaces = false;
            updatedSpace.spaceConfiguration.includesMechVentilation = false;
            updatedSpace.spaceConfiguration.includesNaturalVentilation = false;
            updatedSpace.spaceConfiguration.includesDuctwork = false;
  
            setSwitchStates((prevState) => ({
              ...prevState,
              switch5: false,
              switch6: false,
              switch7: false,
              switch8: false,
            }));
          } else {
            // Dynamically map toggle states to their respective fields
            const fieldMap = {
              switch2: 'includesRDS',
              switch3: 'includesCirculation',
              switch4: 'includesAtrium',
              switch5: 'includesConnectedSpaces',
              switch6: 'includesMechVentilation',
              switch7: 'includesNaturalVentilation',
              switch8: 'includesDuctwork',
            };
  
            const toggleField = fieldMap[id];
            if (toggleField) {
              if (id === 'switch2') {
                // Handle RDS-specific logic
                updatedSpace.unitConfiguration.includesRDS = !switches.switch2;
              } else if (id === 'switch3') {
                updatedSpace.unitConfiguration.includesCirculation = !switches.switch3;              
              } else {
                // General toggle handling
                updatedSpace.spaceConfiguration[toggleField] =
                  !switches[id];
              }
            }
  
            setSwitchStates((prevState) => ({
              ...prevState,
              [id]: !prevState[id],
            }));
          }
  
          return updatedSpace;
        }
        return space;
      });
  
      return updatedSpaces;
    });
  };

  const handleRowSelection = (index) => {
    // First, save the current config
    saveCurrentSpaceConfig();
    
    setSelectedSpaceIndex(index);
    const selectedSpace = spaces[index];
  
    // Update local states
    setUnits([selectedSpace.unitConfiguration || {}]);
    setInstallHeight(selectedSpace.spaceConfiguration.installHeight || '');
    setReleaseOffset(selectedSpace.spaceConfiguration.releaseOffset || '');
    setSpaceName(selectedSpace.spaceConfiguration.spaceName || '');
    setArea(selectedSpace.spaceConfiguration.area || '');
    setSpaceHeight(selectedSpace.spaceConfiguration.spaceHeight || '');
    setDuctworkVolume(selectedSpace.spaceConfiguration.ductworkVolume || '');
    setPlenumVolume(selectedSpace.spaceConfiguration.plenumVolume || '');
  
    // Update toggles
    setSwitchStates((prevState) => ({
      ...prevState,
      switch2: selectedSpace.unitConfiguration.includesRDS || false,
      switch3: selectedSpace.unitConfiguration.includesCirculation || false,
      switch4: selectedSpace.spaceConfiguration.includesAtrium || false,
      switch5: selectedSpace.spaceConfiguration.includesConnectedSpaces || false,
      switch6: selectedSpace.spaceConfiguration.includesMechVentilation || false,
      switch7: selectedSpace.spaceConfiguration.includesNaturalVentilation || false,
      switch8: selectedSpace.spaceConfiguration.includesDuctwork || false,
      // Preserve switch1 state (Release Mitigation)
      switch1: prevState.switch1,
    }));
  
    // Recalculate V_eff for the selected space and update the array
    setSpaces((prevSpaces) => {
      const updatedSpaces = prevSpaces.map((space, i) => {
        if (i === index) {
          return {
            ...space,
            spaceConfiguration: {
              ...space.spaceConfiguration,
              V_eff: calculateVeff(space),
            },
          };
        }
        return space;
      });
      return updatedSpaces;
    });
    if (!selectedSpace.spaceConfiguration.spaceName || selectedSpace.spaceConfiguration.spaceName.trim() === '') {
      // Slight delay to ensure the DOM updates before focusing
      setTimeout(() => {
        if (nameInputRef.current) {
          nameInputRef.current.focus();
        }
      }, 0);
    }
  };

  const handleSpaceFieldChange = (field, value) => {
    setSpaces((prevSpaces) =>
      prevSpaces.map((space, index) => {
        if (index === selectedSpaceIndex) {
          const updatedSpace = {
            ...space,
            unitConfiguration: {
              ...space.unitConfiguration,
              ...(field === "installationHeight" && { installationHeight: parseFloat(value) || 0 }),
              ...(field === "releaseOffset" && { releaseOffset: parseFloat(value) || 0 }),
            },
            spaceConfiguration: {
              ...space.spaceConfiguration,
              [field]: value, // Dynamically update other fields
            },
          };
  
          // Calculate the height
          const height = calculateHeight(updatedSpace.unitConfiguration);
          console.log("Updated height:", height);
  
          // Update M_def based on the new height and floor area
          const floorArea = parseFloat(updatedSpace.spaceConfiguration.area) || 0;
          const M_def = lookupRefrigerantCharge(floorArea, height);
          updatedSpace.unitConfiguration.M_def = M_def;
  
          // Recalculate EDVC and V_eff
          updatedSpace.spaceConfiguration.V_eff = calculateVeff(updatedSpace);
          updatedSpace.spaceConfiguration.EDVC = calculateEDVC(
            updatedSpace,
            refrigerantData[refrigerantType]?.LFL || 0,
            0.5, // CF
            updatedSpace.spaceConfiguration.F_occ || 1, // Default to 1
            M_def,
            refrigerantData[refrigerantType]?.F_LFL || 0
          );
  
          return updatedSpace;
        }
        return space;
      })
    );
  };

  const handleInstallHeightChange = (value) => {
    setInstallHeight(value);
    handleSpaceFieldChange("installationHeight", value);
  };
  
  const handleReleaseOffsetChange = (value) => {
    setReleaseOffset(value);
    handleSpaceFieldChange("releaseOffset", value);
  };

  const handleSysChargeChange = (newSysCharge) => {
    setSysCharge(newSysCharge);
    setSpaces((prevSpaces) => {
      const { LFL, C_LFL, F_LFL } = refrigerantData[refrigerantType] || {};
      return recalculateComplianceForAllSpaces(prevSpaces, LFL || 0, C_LFL || 0.5, F_LFL || 0);
    });
  };

  const handleFactoryChargeChange = (newFactoryCharge) => {
    setFactoryCharge(newFactoryCharge);
    const updatedSysCharge = newFactoryCharge + (parseFloat(fieldCharge) || 0);
    handleSysChargeChange(updatedSysCharge);
};

  const handleFieldChargeChange = (newFieldCharge) => {
      setFieldCharge(newFieldCharge);
      const updatedSysCharge = (parseFloat(factoryCharge) || 0) + newFieldCharge;
      handleSysChargeChange(updatedSysCharge);
  };
  
  const calculateVeff = (space) => {
    const area = parseFloat(space.spaceConfiguration.area) || 0;
    const spaceHeight = parseFloat(space.spaceConfiguration.spaceHeight) || 0;
    const ductworkVolume = parseFloat(space.spaceConfiguration.ductworkVolume) || 0;
    const plenumVolume = parseFloat(space.spaceConfiguration.plenumVolume) || 0;
  
    return area * spaceHeight + (ductworkVolume) + (plenumVolume);
  };

  const calculateEDVC = (space, LFL, CF, F_LFL) => {
    const { includesCirculation, M_def } = space.unitConfiguration;
    const { V_eff, F_occ, Mrel } = space.spaceConfiguration;

    if (!V_eff || !LFL || !CF || !F_occ) {
      console.warn("Missing inputs for EDVC calculation:", {
        V_eff,
        LFL,
        CF,
        F_occ,
        M_def,
        F_LFL,
        includesCirculation,
      });
    }

    let EDVC;

    if (includesCirculation) {
      // Equation (7-8): EDVC = V_eff * LFL * CF * F_occ
      EDVC = (V_eff || 0) * (LFL / 1000 || 0) * (CF || 0.5) * (F_occ || 1);
    } else {
      // Equation (7-9): EDVC = M_def * F_LFL * F_occ
      EDVC = (M_def || 0) * (F_LFL || 0) * (F_occ || 1);
    }

    // Update compliance status
    const isCompliant = sysCharge < EDVC;
    space.unitConfiguration.isCompliant = isCompliant;

    return EDVC;
  };

  const calculateHeight = (unitConfiguration) => {
    const installationHeight = parseFloat(unitConfiguration.installationHeight) || 0;
    const releaseOffset = parseFloat(unitConfiguration.releaseOffset) || 0;
    const height = installationHeight + releaseOffset;
    console.log("Calculated height:", height); // Debug
    return height;
  };

  const recalculateComplianceForAllSpaces = (spaces, LFL, CF, F_LFL) => {
    return spaces.map((space) => {
      const updatedEDVC = calculateEDVC(space, LFL, CF, F_LFL);
      return {
        ...space,
        spaceConfiguration: {
          ...space.spaceConfiguration,
          EDVC: updatedEDVC,
        },
        unitConfiguration: {
          ...space.unitConfiguration,
          isCompliant: space.unitConfiguration.isCompliant, // Updated inside calculateEDVC
        },
      };
    });
  };
    
  // Update EDVC whenever needed
  const updateEDVC = () => {
    setSpaces((prevSpaces) =>
      prevSpaces.map((space, index) => {
        const updatedSpace = {
          ...space,
          spaceConfiguration: {
            ...space.spaceConfiguration,
            EDVC: calculateEDVC(space), // Calculate EDVC based on the conditions
          },
        };
        return updatedSpace;
      })
    );
  };

  const toggleSwitch = () => {
    setIsSwitchOn((prevState) => !prevState);
  }

  const renderComplianceCheckmark = (isCompliant) => {
    return (
      <span
        className={`checkmark-icon ${isCompliant ? 'complete' : 'incomplete'}`}
      ></span>
    );
  };

  return (
    <div className="ASH-dashboard-container">
      {/* Left Column */}
        <div className="ASH-dashboard-left">

            {/* -----------System Config Card-------------- */}
            <div className="ASH-dashboard-card system-configuration">
                <h5>System Configuration</h5>
                <h1 style={{ marginTop: '0.5rem'}}>{sysCharge} lb</h1><label style={{ marginBottom: '0.5rem'}}>System charge</label>
                <div className="dropdown-container">
                    <select onChange={handleRefrigerantChange} style={{ width: '100px' }} >
                    <option value="" disabled selected>...</option>
                    <option value="R-410A">R-410A</option>
                    <option value="R-32">R-32</option>
                    <option value="R-452B">R-452B</option>
                    <option value="R-454A">R-454A</option>
                    <option value="R-454B">R-454B</option>
                    <option value="R-454C">R-454C</option>
                    <option value="R-457A">R-457A</option>
                    </select>
                    <label> Refrigerant Type</label>                  
                </div>
                <div className="charge-inputs-container">
                    <input 
                        type="number" 
                        value={factoryCharge === 0 ? '' : factoryCharge} // Show placeholder if value is 0
                        onChange={(e) => handleFactoryChargeChange(parseFloat(e.target.value) || 0)}
                        placeholder="lb"  // Placeholder with unit
                        required 
                    />
                    <label> Factory Charge</label><br /> 
                </div>
                <div className="charge-inputs-container">
                    <input 
                        type="number" 
                        value={fieldCharge === 0 ? '' : fieldCharge} // Show placeholder if value is 0 
                        onChange={(e) => handleFieldChargeChange(parseFloat(e.target.value) || 0)}
                        placeholder="lb"  // Placeholder with unit
                    />
                    <label> Field Charge</label>
                </div>
                <div className="toggle-container">
                    <div 
                    className={`toggle-switch ${switches.switch1 ? 'on' : ''}`} 
                    onClick={() => handleToggle('switch1')}
                    >
                    <div className="toggle-thumb"></div>
                    </div>
                    <label className="toggle-label" style={{ marginLeft: '10px' }}> Release Mitigation</label>
                </div>
            </div>
            



            {/* -------------------------------Unit Config Card------------------------------------ */}
            <div className="ASH-dashboard-card unit-configuration">
                <h5>Indoor Unit Configuration</h5>
                <div className="charge-inputs-container">
                    <input 
                        type="number" 
                        value={installHeight} 
                        onChange={(e) => handleInstallHeightChange(e.target.value)}  
                        placeholder="ft"  // Placeholder with unit
                        className="input" 
                        required 
                    />
                    <label> Install Height</label>
                    <FaInfoCircle onClick={() => handleModalOpen('installHeightInfo')} style={{ marginLeft: '5px', cursor: 'pointer' }} />
                </div>
                <div className="charge-inputs-container">
                    <input 
                        type="number" 
                        value={releaseOffset} 
                        onChange={(e) => handleReleaseOffsetChange(e.target.value)} 
                        placeholder="ft"  // Placeholder with unit
                        className="input"
                    />
                    <label> Release Offset</label>
                    <FaInfoCircle onClick={() => handleModalOpen('releaseOffsetInfo')} style={{ marginLeft: '5px', cursor: 'pointer' }} />
                </div>
                <div className="toggle-container">
                    <div 
                    className={`toggle-switch ${switches.switch2 ? 'on' : ''}`} 
                    onClick={() => handleToggle('switch2')}
                    >
                    <div className="toggle-thumb"></div>
                    </div>
                    <label className="toggle-label" style={{ marginLeft: '10px' }}> RDS</label>
                </div>
                <div className="toggle-container">
                <div 
                    className={`toggle-switch ${switches.switch3 ? 'on' : ''}`} 
                    onClick={() => {
                        handleToggle('switch3');
                        // Recalculate EDVC for the active space when Circulation is toggled
                        setSpaces((prevSpaces) =>
                            prevSpaces.map((space, index) => {
                                if (index === selectedSpaceIndex) {
                                    const updatedSpace = {
                                        ...space,
                                        unitConfiguration: {
                                            ...space.unitConfiguration,
                                            includesCirculation: !switches.switch3,
                                        },
                                    };
                                    const newEDVC = calculateEDVC(
                                        updatedSpace,
                                        refrigerantData[refrigerantType]?.LFL || 0,
                                        0.5, // CF
                                        refrigerantData[refrigerantType]?.F_LFL || 0
                                    );
                                    updatedSpace.spaceConfiguration.EDVC = newEDVC;
                                    return updatedSpace;
                                }
                                return space;
                            })
                        );
                    }}
                >
                    <div className="toggle-thumb"></div>
                </div>
                <label className="toggle-label" style={{ marginLeft: '10px' }}> Circulation</label>
                {switches.switch3 && !switches.switch2 && (
                    <span onClick={() => handleModalOpen('circulationWarning')} className="info-icon" style={{ marginLeft: '5px', color: 'white', background: 'red', fontWeight: 'bold' }}>
                        &#33; {/* Exclamation mark icon */}
                    </span>
                )}
            </div>

            </div>




            {/* -----------------------Space Config Card-------------------------------------------- */}
            <div className="ASH-dashboard-card space-configuration">
                <h5>Space Configuration</h5>
                <h1 style={{ marginTop: '0.5rem'}}>{Number(edvc).toFixed(1)} lb</h1><label style={{ marginBottom: '0.5rem'}}>Effective Dispersal Volume Charge</label>
                <div className="charge-inputs-container">
                    <input 
                        type="text" 
                        value={spaceName}
                        onChange={(e) => {
                          const value = e.target.value;
                          setSpaceName(value);
                          handleSpaceFieldChange("spaceName", value);
                        }}  
                        placeholder="Name"  // Placeholder with unit
                        className="input" 
                        required 
                        ref={nameInputRef}
                    />
                    <label> Name</label>
                </div>
                <div className="charge-inputs-container">
                    <input 
                        type="number" 
                        value={area}
                        onChange={(e) => {
                          const value = parseFloat(e.target.value) || 0;
                          setArea(value);
                          handleSpaceFieldChange("area", value);
                        }} 
                        placeholder="Area (ft2)"  // Placeholder with unit
                        className="input"
                    />
                    <label> Area (ft<sup>2</sup>)</label>
                </div>
                <div className="charge-inputs-container">
                    <input 
                        type="number" 
                        value={spaceHeight}
                        onChange={(e) => {
                          const value = parseFloat(e.target.value) || 0;
                          setSpaceHeight(value);
                          handleSpaceFieldChange("spaceHeight", value);
                        }} 
                        placeholder="Space Height (ft)"  // Placeholder with unit
                        className="input"
                        disabled={switches.switch4} // Disable if Atrium toggle is on
                    />
                    <label> Space Height (ft)</label>
                </div>
                <div className="toggle-container">
                    <div 
                    className={`toggle-switch ${switches.switch4 ? 'on' : ''}`} 
                    onClick={() => handleToggle('switch4')}
                    >
                    <div className="toggle-thumb"></div>
                    </div>
                    <label className="toggle-label" style={{ marginLeft: '10px' }}> Atrium</label>
                    <FaInfoCircle onClick={() => handleModalOpen('atriumInfo')} style={{ marginLeft: '5px', cursor: 'pointer' }} />
                </div>
                <div className="toggle-container">
                    <div 
                    className={`toggle-switch ${switches.switch5 ? 'on' : ''}`} 
                    onClick={() => handleToggle('switch5')}
                    >
                    <div className="toggle-thumb"></div>
                    </div>
                    <label className="toggle-label" style={{ marginLeft: '10px' }}> Connected Spaces...</label>
                    <FaInfoCircle onClick={() => handleModalOpen('connectedSpacesInfo')} style={{ marginLeft: '5px', cursor: 'pointer' }} />
                </div>
                <div className="toggle-container">
                    <div 
                    className={`toggle-switch ${switches.switch6 ? 'on' : ''}`} 
                    onClick={() => switches.switch5 && handleToggle('switch6')} // Only allow if Connected Spaces is on
                    >
                    <div className="toggle-thumb"></div>
                    </div>
                    <label className="toggle-label" style={{ marginLeft: '10px' }}> ...by Mech Ventilation</label>
                </div>
                <div className="toggle-container">
                    <div 
                    className={`toggle-switch ${switches.switch7 ? 'on' : ''}`} 
                    onClick={() => switches.switch5 && handleToggle('switch7')} // Only allow if Connected Spaces is on
                    >
                    <div className="toggle-thumb"></div>
                    </div>
                    <label className="toggle-label" style={{ marginLeft: '10px' }}> ...by Natural Ventilation</label>
                </div>
                <div className="toggle-container">
                    <div 
                    className={`toggle-switch ${switches.switch8 ? 'on' : ''}`} 
                    onClick={() => switches.switch5 && handleToggle('switch8')} // Only allow if Connected Spaces is on
                    >
                    <div className="toggle-thumb"></div>
                    </div>
                    <label className="toggle-label" style={{ marginLeft: '10px' }}> ...by Ductwork</label>
                </div>
                <div className="charge-inputs-container">
                    <input 
                        type="number" 
                        value={ductworkVolume} 
                        onChange={(e) => {
                          const value = parseFloat(e.target.value) || 0;
                          setDuctworkVolume(value);
                          handleSpaceFieldChange("ductworkVolume", value);
                        }}
                        placeholder="Ductwork Volume"  // Placeholder with unit
                        className="input"
                        disabled={!switches.switch8} // Disable if Ductwork toggle is off
                    />
                    <label> Ductwork Volume (ft<sup>3</sup>)</label>
                </div>
                <div className="charge-inputs-container">
                    <input 
                        type="number" 
                        value={plenumVolume}
                        onChange={(e) => {
                          const value = parseFloat(e.target.value) || 0;
                          setPlenumVolume(value);
                          handleSpaceFieldChange("plenumVolume", value);
                        }} 
                        placeholder="Plenum Volume"  // Placeholder with unit
                        className="input"
                        disabled={!switches.switch8} // Disable if Ductwork toggle is off
                    />
                    <label> Plenum Volume (ft<sup>3</sup>)</label>
                </div>
            </div>
      </div>
  
      {/* Main Content Area */}
      <div className="ASH-dashboard-main">
{/*         <div className="ASH-dashboard-card floorplan">
          <h5>Floorplan View</h5>
        </div> */}
        <div className="ASH-dashboard-card space-summary">
          <div className="space-summary-header" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
            <h5 style={{ margin: 0, flex: '0 0 auto' }}>Spaces Summary</h5>
            <div style={{ marginLeft: 'auto' }}>
              <button onClick={addSpace}>Add Space</button>
              <button onClick={() => removeSpace(selectedSpaceIndex)} disabled={selectedSpaceIndex === null}>Remove Space</button>
            </div>
          </div>
          <table className="space-summary-table">
          <thead>
            <tr>
              <th style={{ textAlign: 'center' }}>Name</th>
              <th style={{ textAlign: 'center' }}>Area</th>
              <th style={{ textAlign: 'center' }}>V<sub>eff</sub></th>
              <th style={{ textAlign: 'center' }}>Circulation</th>
              <th style={{ textAlign: 'center' }}>Detector</th>
{/*               <th style={{ textAlign: 'center' }}>Ductwork Volume</th> */}
              <th style={{ textAlign: 'center' }}>m<sub>s</sub></th>
{/*               <th style={{ textAlign: 'center' }}>C<sub>LFL</sub></th>
              <th style={{ textAlign: 'center' }}>F<sub>LFL</sub></th> */}
              <th style={{ textAlign: 'center' }}>EDVC</th>
              <th style={{ textAlign: 'center' }}>m<sub>rel</sub></th>
              <th style={{ textAlign: 'center' }}>Pass?</th>
            </tr>
          </thead>
          <tbody>
            {spaces.map((space, index) => (
                <tr
                  key={index}
                  onClick={() => handleRowSelection(index)}
                  className={selectedSpaceIndex === index ? 'selected' : ''}
                >
                <td>{space.spaceConfiguration.spaceName}</td>
                <td>{space.spaceConfiguration.area}</td>
                <td>{Number(space.spaceConfiguration.V_eff || 0).toLocaleString(undefined, { maximumFractionDigits: 0 })}</td>
                <td>{space.unitConfiguration.includesCirculation ? <FaCheck /> : '-'}</td>
                <td>{space.unitConfiguration.includesRDS ? <FaCheck /> : '-'}</td>
{/*                 <td>{space.spaceConfiguration.ductworkVolume}</td> */}
                <td>{sysCharge}</td>
{/*                 <td>{C_LFL}</td>
                <td>{F_LFL}</td> */}
                <td>{Number(space.spaceConfiguration.EDVC || 0).toFixed(2)}</td>
                <td>{space.spaceConfiguration.mRel}</td>
                <td>{space.unitConfiguration.isCompliant ? renderComplianceCheckmark(space.unitConfiguration.isCompliant) : '-'}</td>
              </tr>
            ))}
          </tbody>
          </table>
        </div>

      </div>
      
      {/* Info Modals */}

      {openModals.installHeightInfo && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={() => handleModalClose('installHeightInfo')}>&times;</span>
            <p>Installation height refers to the height at which the unit is installed, measured from the ground.</p>
          </div>
        </div>
      )}

      {openModals.releaseOffsetInfo && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={() => handleModalClose('releaseOffsetInfo')}>&times;</span>
            <p>The <b>release offset</b> refers to the distance from the bottom of the unit to lowest opening point which is large enough to leak refrigerant.</p>
          </div>
        </div>
      )}

      {openModals.atriumInfo && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={() => handleModalClose('atriumInfo')}>&times;</span>
            <p>Where different stories and floor levels connect through an open <b>atrium</b> or mezzanine, the <i>effective dispersal volume</i> shall be determined by multiplying the floor area of the lowest floor level by 8.2 ft (2.5 m) ceiling height.</p>
          </div>
        </div>
      )}

      {openModals.connectedSpacesInfo && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={() => handleModalClose('connectedSpacesInfo')}>&times;</span>
            <p>Connected spaces details...</p>
          </div>
        </div>
      )}

      {openModals.circulationWarning && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={() => handleModalClose('circulationWarning')}>&times;</span>
            <p>Applying <i>Circulation</i> which is not activated by an RDS requires the <i>circulation</i> to operate continuosly...among other requiremnts</p>
          </div>
        </div>
      )}

    </div>
  );
  
};

export default Ashrae15;
