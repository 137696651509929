// src/Login.js
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom'; // Ensure Link is imported
import './styles.css';

const apiUrl = process.env.REACT_APP_DB_USERS + process.env.REACT_APP_LOGIN_HANDLER;
//console.log('API URL:', apiUrl);

const Login = ({ setLoggedIn }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate(); //corrected to useNavigate

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);  // Start loading
    try {
      const response = await axios.post(apiUrl, { email, password });
      if (response.data.success) {
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('is_admin', response.data.is_admin);
        localStorage.setItem('user_email', response.data.email);
        console.log('Logged in as:', response.data.email);
        console.log('Admin status:', response.data.is_admin);
        setLoggedIn(true);  // ✅ Update login state immediately
        navigate('/');
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error('Login error', error);
      alert('An error occurred. Please try again.');
    } finally {
      setIsLoading(false);  // Stop loading
    }
};


return (
  <div className="login-page">
    <div className="login-container">
      <h1>Login</h1>
      <form onSubmit={handleSubmit}>
        <label>Email:</label>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <label>Password:</label>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <button type="submit" disabled={isLoading}>
          {isLoading ? 'Logging in...' : 'Login'}
        </button>
      </form>
      {isLoading && <div className="spinner"></div>}  {/* Show spinner if loading */}
      <p>
        Don't have an account? <Link to="/register">Register here</Link>
      </p>
    </div>
  </div>
);

};

export default Login;
