import React from 'react';

const PrivacyPolicy = () => {

  return (
    <>
      <div className="admin-content">
        <h1>Privacy Policy</h1>
        <hr className="account-divider" />
        <div className="privacy-policy-container">
          <p>Effective Date: February 9th, 2025</p>
          
          <h2>1. Introduction</h2>
          <p>Welcome to RCLcheck.com. Your privacy is important to us, and we are committed to protecting any information you may provide while using our services. This Privacy Policy outlines our policies and practices regarding data collection, usage, and protection.</p>

          <h2>2. Information We Do Not Collect</h2>
          <p>We do not collect, store, or process any personally identifiable information ("Personal Information") from users of our website and services. Our platform does not require users to provide names, addresses, phone numbers, or any other personal details.</p>

          <h2>3. Non-Personal Data</h2>
          <p>While we do not collect Personal Information, we may collect non-personal data such as aggregated analytics to improve the performance and functionality of our website. This may include data such as browser type, operating system, and general usage trends. Such data is anonymized and cannot be used to identify individuals.</p>

          <h2>4. Cookies and Tracking Technologies</h2>
          <p>We may use cookies or similar technologies to enhance the user experience. These technologies do not collect Personal Information and are only used for website performance and usability enhancements.</p>

          <h2>5. Third-Party Services</h2>
          <p>We do not sell, trade, or share any user data with third parties. However, our website may include links to third-party services or integrations (such as authentication providers or embedded content). Any interactions with third-party services are governed by their respective privacy policies.</p>

          <h2>6. Security Measures</h2>
          <p>We take appropriate measures to ensure that our website and services remain secure. However, as no system is completely immune to cyber threats, we cannot guarantee absolute security.</p>

          <h2>7. Changes to This Policy</h2>
          <p>We reserve the right to update or modify this Privacy Policy at any time. Any changes will be posted on this page, and we encourage users to review this policy periodically.</p>

          <h2>8. Contact Us</h2>
          <p>If you have any questions or concerns about this Privacy Policy, you may contact us at:</p>
          <p>[Insert Contact Information]</p>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;

