// src/Register.js
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './styles.css';

const Register = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const apiUrl = process.env.REACT_APP_DB_USERS + process.env.REACT_APP_REGISTER_HANDLER;

  const [message, setMessage] = useState({ text: '', type: '' });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);  // Start loading
    try {
      const response = await axios.post(apiUrl, { email, password });
      if (response.data.success) {
        setMessage({ text: 'Registration successful!', type: 'success' });
        setTimeout(() => navigate('/login'), 3000);
        console.log("Registration successful!");
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || (error.request ? 'Unable to connect to the server. Please check your network and try again.' : 'Registration failed. Please try again.');
      setMessage({ text: errorMessage, type: 'error' });
      console.log("Error message set:", errorMessage);
      console.error('Registration error', error);
    } finally {
      setIsLoading(false);  // Stop loading
    }
  };

 return (
    <div className="login-page">
    {message.text && (
      <div className={`message-bar ${message.type} ${message.text ? 'show' : ''}`}>
        <button className="close-button" onClick={() => setMessage({ text: '', type: '' })}>×</button>
        {message.text}
      </div>
    )}
    <div className="login-container">
      <h1>Register</h1>
      <form onSubmit={handleSubmit}>
        <label>Email:</label>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <label>Password:</label>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <button type="submit">Register</button>
      </form>
      {isLoading && <div className="spinner"></div>}  {/* Show spinner if loading */}
    </div>
  </div>
 )
};

export default Register;
