import React from 'react';
import './styles.css';
import './Footer.css';

const Footer = () => {

  return (
    <footer className="footer-section">
    <div className="left-content">
      <a href="#">© 2025</a>
      <a href="./PrivacyPolicy">Privacy Policy</a>
      <a href="./PrivacyPolicy">Terms of Service</a>
    </div>
    <div className="right-content">
      <a href="#"><i className="fab fa-facebook-f"></i></a>
      <a href="#"><i className="fab fa-twitter"></i></a>
      <a href="#"><i className="fab fa-linkedin-in"></i></a>
      <a href="#"><i className="fab fa-instagram"></i></a>
    </div>
    </footer>
  );
};

export default Footer;
